import React, { Component } from "react";
import "../sass/component/available-at.scss";
import ButtonEdit from "../element/ButtonEdit";
import { connect } from "react-redux";
import { AVAILABLE_AT } from "../config/content";
import ContentApi from "../api/content";
import ModalEditAvailable from "./ModalEditAvailable";

class AvailableAt extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal_edit: false
    };

    this.onEdit = this.onEdit.bind(this);
    this.openEdit = this.openEdit.bind(this);
    this.closeEdit = this.closeEdit.bind(this);

  }
  renderLogo() {
    return this.props.data.map(({ alt, img }, i) => <div key={i} className="aa-logo">
      <img key={i} src={`${process.env.REACT_APP_API_URL}${img}`} alt={alt} />
    </div>);
  }
  render() {
    return <div className="aa-container">
      <ButtonEdit className="button-sm btn-banner" onClick={this.openEdit} />
      <div className="vit-title">
        <span>{this.props.title}</span>
      </div>
      <br /><br />
      <div className="aa-logo-container">
        {this.renderLogo()}
      </div>
      <br />
      <ModalEditAvailable title={this.props.title} img={this.props.img} data={this.props.data} show={this.state.modal_edit} onClickBtnOK={this.onEdit} onClickBtnCancel={this.closeEdit} />
    </div>;
  }

  onEdit(data, files, file_bg) {
    this.props.dispatch({
      type: "SHOW_CONFIRM",
      payload: "Are you sure you want to save changes ?",
      callback: () => ContentApi.updateWithFiles({ cten_name: AVAILABLE_AT, cten_value: data, files, file_bg }, this.props.dispatch)
        .then((data) => {
          this.closeEdit();
          this.props.dispatch({ type: "SHOW_INFO", payload: "Data sucessfully updated" });
          this.props.dispatch({ type: "UPDATE_CONTENTS", payload: { [AVAILABLE_AT]: data } });
        })
    });
  }

  closeEdit() {
    this.setState({ modal_edit: false });
  }

  openEdit() {
    this.setState({ modal_edit: true });
  }
}

AvailableAt.defaultProps = {
  data: [],
  title: ""
};

export default connect(({ content: { [AVAILABLE_AT]: data } }) => (data))(AvailableAt);