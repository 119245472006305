import React, { Component } from "react";
import "../sass/component/news-link-item.scss";
import moment from "moment";
import ButtonEdit from "../element/ButtonEdit";

class NewsItem extends Component {

    render() {
        return <div className="nli-container">
            <div className="nli-img-container" onClick={this.props.onClick}>
                <img alt="bali asli" src={`${process.env.REACT_APP_API_URL}${this.props.data.news_img}`} />
            </div>
            <div className="nli-text-container" onClick={this.props.onClick}>
                <div className="nli-date">{moment(this.props.data.news_date).format("DD MMMM YYYY")}</div>
                <div className="nli-title">{this.props.data.news_title}</div>
            </div>
            <div className="nli-btn-container">
                <ButtonEdit className="button-sm" onClick={() => this.props.onClickEdit(this.props.data)}>Edit</ButtonEdit>
                <ButtonEdit className="button-sm" onClick={this.props.onClickDelete} >Delete</ButtonEdit>
            </div>
            
        </div>;
    }
}

NewsItem.defaultProps = {
    data: {}
};

export default NewsItem;