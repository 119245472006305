import React, { Component } from "react";
import "../sass/component/slider-item-flex.scss";
import Button from "../element/ButtonSlideUp";
import { withRouter } from "react-router";

class SliderItem extends Component {
    render() {
        return <div className="slider-item" style={{backgroundImage: `url(${process.env.REACT_APP_API_URL}${this.props.data.img})`}}>
            <div className="sliflex-container" >
                <div className="sli-subtitle"><h3>{this.props.data.subtitle}</h3></div>
                <div className="sli-title"><h1>{this.props.data.title}</h1></div>
                {/* <div className="sli-separator"><div className="separator"></div></div> */}
                <div className="sli-desc">{this.props.data.desc}</div>
                {this.props.data.url && <div className="sli-button"><Button className="button-sm dark" onClick={() => window.location = this.props.data.url}>{this.props.data.button_text}</Button></div>}
            </div>
        </div>;
    }
}

SliderItem.defaultProps = {
    data:{}
};

export default withRouter(SliderItem);