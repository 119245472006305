import React, { Component } from "react";
import "../sass/page/about.scss";
import Separator from "../element/Separator";
import { withRouter } from "react-router";
import Page from "./Page";
import ButtonSlideUp from "../element/ButtonSlideUp";

class PaymentFinish extends Component {

    render() {
        return <Page>
            <div style={{ textAlign: "center", fontSize: "2.5rem" }}>
                <Separator />
                Thank you !
                <Separator height="2rem" />
                <span style={{ fontSize: "2rem" }}>Payment has been successful, our team will process your order immediately!</span>
                <Separator height="2rem" />
                <ButtonSlideUp onClick={() => this.props.history.replace("/")} className="button-sm">Back to home</ButtonSlideUp>
                <Separator height="13rem"/>
                <br/>
            </div>
        </Page>;
    }
}

export default withRouter(PaymentFinish);