import React, { Component } from "react";
import "../sass/element/modal.scss";
import Button from "./ButtonSlideUp";

class Modal extends Component {

    render() {
        let style = {
            "display": "block",
            "visibility": this.props.show ? "visible" : "hidden",
            "opacity": this.props.show ? "1" : "0",
            "backgroundColor": "rgba(0, 0, 0, 0.5)"
        };
        return <div className={"modal animate-drop "} id={this.props.id} style={style}>
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    {this.props.title && <div className="modal-header">
                        <h4 className="modal-title">{this.props.title}</h4>
                        <button type="button" onClick={this.props.onClickBtnCancel} className="close" style={{ fontSize: "2rem" }}>&times;</button>
                    </div>}
                    <div className="modal-body">{this.props.children}</div>
                    <div className="modal-footer">
                        {this.props.btn_ok && <Button className="button-sm" onClick={this.props.onClickBtnOK}>{this.props.btn_ok}</Button>}
                        {this.props.btn_cancel && <Button className="button-sm" onClick={this.props.onClickBtnCancel}>{this.props.btn_cancel}</Button>}
                    </div>
                </div>
            </div>
        </div>;
    }
}

export default Modal;