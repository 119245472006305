const getCart = () => {
  let cart = {};
  try {
    cart = JSON.parse(localStorage.getItem("cart")) || {};
  } catch (e) {
    console.log(e);
  }
  return cart;
};

export default function reducer(
  state = {
    cart: getCart(),
    showCart: false,
    msgCart: "",
    cbCart: false,
  },
  { type, payload, callback }
) {
  switch (type) {
    case "UPDATE_QUERY": {
      return {
        ...state,
        query: payload,
      };
    }
    case "RESET_QUERY": {
      return {
        ...state,
        query: "",
      };
    }
    case "SHOW_CART": {
      return { ...state, showCart: true, msgCart: payload, cbCart: callback };
    }
    case "HIDE_CART": {
      return { ...state, showCart: false, cbCart: false };
    }
    case "ADD_TO_CART": {
      let { cart } = state;
      let { product, qty } = payload;
      let { prd_id } = product;
      cart[prd_id] = cart[prd_id] || { ...product, qty: 0 };
      cart[prd_id].qty += qty;
      localStorage.setItem("cart", JSON.stringify(cart));
      return { ...state, cart, showCart: false, cbCart: false };
    }
    case "MODIFY_CART": {
      let cart = { ...state.cart };
      let { product, qty } = payload;
      let { prd_id } = product;
      cart[prd_id] = { ...cart[prd_id] } || product;
      cart[prd_id].qty = qty;
      localStorage.setItem("cart", JSON.stringify(cart));
      return { ...state, cart, cbCart: false };
    }
    case "DELETE_FROM_CART": {
      let cart = { ...state.cart };
      delete cart[payload];
      localStorage.setItem("cart", JSON.stringify(cart));
      return { ...state, cart, cbCart: false };
    }
    case "RESET_CART": {
      return {
        ...state,
        cart: {},
        cbCart: false,
      };
    }
    default:
      return state;
  }
}
