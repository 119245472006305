import React, { Component } from "react";
import "../sass/component/modal-detail-item.scss";
import Modal from "../element/Modal";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import QtyBox from "./QtyBox";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const hideItemDescription = true;

class ModalDetailItem extends Component {

    constructor(props) {
        super(props);
        this.state = {
            qty: 1,
            index_variant: 0,
            index_id: 0
        };

        this.onClickBtnOK = this.onClickBtnOK.bind(this);
    }

    componentWillReceiveProps(props) {
        if (props.show !== this.props.show) {
            this.setState({
                qty: 1,
                index_variant: 0,
                index_id: 0
            });
        }
    }

    onClickBtnOK() {
        let { prd_stock } = this.props.data.prd_variants[this.state.index_variant].prd_details[this.state.index_id];
        if (prd_stock) {
            this.props.dispatch({
                type: "ADD_TO_CART",
                payload: {
                    product: this.props.data.prd_variants[this.state.index_variant].prd_details[this.state.index_id],
                    qty: this.state.qty
                }
            });
            this.props.onClickBtnOK();
        }
    }

    renderTabs(prd_variant) {
        if (hideItemDescription) return <div>
            <h3>Ingredients</h3>
            <br />
            {this.props.data.prd_ingr}
        </div>;

        return <Tabs>
            <TabList>
                <Tab>Description</Tab>
                <Tab>Ingredients</Tab>
            </TabList>

            <TabPanel>
                {this.props.data.prd_desc}
                <br />
            </TabPanel>
            <TabPanel>
                {this.props.data.prd_ingr}
            </TabPanel>
        </Tabs>;
    }

    renderPrice() {
        let prd_variant = this.props.data.prd_variants[this.state.index_variant];
        let prd_detail = prd_variant.prd_details[this.state.index_id];
        return <div className="mdi-price">
            {this.renderTabs(prd_variant)}
            <div className="space" />
            <div className="mdi-qty">
                <div />
                <div style={{ textAlign: "right" }}>Size : <select onChange={(e) => this.setState({ index_id: e.target.value })} value={this.state.index_id}>{prd_variant.prd_details.map((row, i) => <option value={i} key={i}>{row.prd_size}</option>)}</select>
                    <br />
                    IDR {(prd_detail.prd_price || 0).toLocaleString()}</div>
            </div>
            <br />
            {this.renderQty()}
        </div>;
    }

    renderQty() {
        let { prd_stock } = this.props.data.prd_variants[this.state.index_variant].prd_details[this.state.index_id];
        return prd_stock ? <div className="mdi-qty">
            <div>Quantity</div>
            <QtyBox qty={this.state.qty} onChange={(qty) => this.setState({ qty })} />
        </div> : <div style={{ textAlign: "right" }}>Item out of stock</div>;
    }

    render() {
        let prd_img = this.props.data.prd_variants[this.state.index_variant].prd_details[this.state.index_id].prd_img;
        return <Modal id="modal-detail-item"
            show={this.props.show}
            btn_ok="Add to Cart"
            btn_cancel="Cancel"
            title={this.props.data.prd_name}
            onClickBtnOK={this.onClickBtnOK}
            onClickBtnCancel={this.props.onClickBtnCancel}>
            <div className="mdi-container">
                <div className="mdi-image">
                    <img alt={`Bali Asli ${this.props.data.prd_name}`} src={`${process.env.REACT_APP_API_URL}${prd_img}`} />
                    {this.renderImages()}
                </div>
                {this.renderPrice()}
            </div>
        </Modal>;
    }

    renderImages() {
        let products = this.props.data.prd_variants.reduce((arr, row, index_variant) => {
            let { prd_img } = row.prd_details[0];
            arr.push(<img key={index_variant} class={`mdi-img-variant${index_variant === this.state.index_variant ? " active" : ""}`} alt="" onClick={() => this.setState({ index_variant, index_id: 0 })} src={`${process.env.REACT_APP_API_URL}${prd_img}`} />);
            return arr;
        }, []);

        return <div className="mdi-img-variant-container">
            {products}
        </div>;
    }
}

ModalDetailItem.defaultProps = {
    data: {
        prd_variants: [{}]
    }
};

export default withRouter(connect(() => ({}))(ModalDetailItem));