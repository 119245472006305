import request from "superagent";
import Helper from "./helper";

export default {
  changePassword: (data, dispatch) =>
    new Promise((resolve, reject) => {
      Helper.onRequest(dispatch);
      request
        .put(`${process.env.REACT_APP_API_URL}/admin/password`)
        .set("Accept", "application/json")
        .set("Authorization", "Bearer " + localStorage.getItem("jwt"))
        .send(data)
        .end((err, res) => {
          Helper.onResponse(dispatch);
          if (res && res.body.status === "success") {
            resolve(res.body.payload);
          } else {
            Helper.onError(err, res, dispatch);
          }
        });
    }),
  login: ({ username, password }, dispatch) =>
    new Promise((resolve, reject) => {
      Helper.onRequest(dispatch);
      request
        .post(process.env.REACT_APP_API_URL + "/admin/login")
        .send({ username, password })
        .set("Accept", "application/json")
        .end((err, res) => {
          Helper.onResponse(dispatch);
          if (res && res.body.status === "success") {
            resolve(res.body.payload);
          } else {
            Helper.onError(err, res, dispatch);
          }
        });
    }),
  test: (dispatch) =>
    new Promise((resolve, reject) => {
      Helper.onRequest(dispatch);
      request
        .get(process.env.REACT_APP_API_URL + "/admin/test")
        .set("Accept", "application/json")
        .set("Authorization", "Bearer " + localStorage.getItem("jwt"))
        .end((err, res) => {
          Helper.onResponse(dispatch);
          if (res && res.body.status === "success") {
            resolve(res.body.payload);
          } else {
            Helper.onError(err, res, dispatch);
          }
        });
    }),
};
