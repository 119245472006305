import React, { Component } from "react";
import "../sass/page/about.scss";
import Separator from "../element/Separator";
import SalesApi from "../api/sales";
import { connect } from "react-redux";
import Page from "./Page";
import { withRouter } from "react-router-dom";
import moment from "moment";
import ModalDetailSales from "../component/ModalDetailSales";
import PAY_STATUS from "../config/payment_status";
import ORDER_STATUS from "../config/order_status";

// const PAY_STATUS = {
//     0: "Unpaid",
//     1: "Pending",
//     2: "Paid",
//     3: "Paid",
//     4: "Cancelled",
//     5: "Expired"
// }

const PER_PAGE = 20;

// const ORDER_STATUS = {
//     0: "Awaiting",
//     1: "Sent"
// }

class Sales extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal_detail: false,
            sales: [],
            pg_current: 1,
            pg_total: 1,
            total: 0
        };

        this.getPage = this.getPage.bind(this);
        this.updateSales = this.updateSales.bind(this);

    }

    componentWillReceiveProps(props) {
        if (!props.isSignedIn) {
            props.history.replace("/");
            return;
        }
    }

    componentWillMount() {

        if (!this.props.isSignedIn) {
            this.props.history.replace("/");
            return;
        }

        this.getPage(this.state.pg_current);
    }

    renderSales() {
        return this.state.sales.map(({ sale_id, cnt_name, sale_total, created_date, sale_pay_status, sale_status, sale_ship_no }, i) => <tr onClick={() => this.setState({ modal_detail: true, selected_index: i })} className="tr-sales" key={i}>
            <td>{sale_id}</td>
            <td>{cnt_name}</td>
            <td className="number">{sale_total.toLocaleString()}</td>
            <td>{moment.unix(created_date).format("DD MMM YYYY")}</td>
            <td>{PAY_STATUS[sale_pay_status]}</td>
            <td>{ORDER_STATUS[sale_status]}</td>
            <td>{sale_ship_no || "-"}</td>
        </tr>);
    }

    renderData() {
        return <table className="table">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th className="number">Sales Total</th>
                    <th>Date</th>
                    <th>Pay Status</th>
                    <th>Order Status</th>
                    <th>Shipment No</th>
                </tr>
            </thead>
            <tbody>
                {this.renderSales()}
            </tbody>
        </table>;
    }

    renderNoData() {
        return <div style={{ textAlign: "center", fontSize: "2rem" }}>You have no sales <br />to be displayed</div>;
    }

    render() {

        return <Page>
            {this.state.sales.length === 0 && this.renderNoData()}
            {this.state.sales.length > 0 && this.renderData()}
            {this.state.sales.length > 0 && this.renderPagination()}
            <Separator />
            <ModalDetailSales show={this.state.modal_detail} data={this.state.sales[this.state.selected_index]} onClickBtnCancel={() => this.setState({ modal_detail: false })} onClickBtnOK={this.updateSales} />
        </Page>;
    }

    updateSales(sale_status, sale_ship_no) {
        let data = {
            sale_id: this.state.sales[this.state.selected_index].sale_id,
            sale_status, sale_ship_no
        };
        SalesApi.update(data, this.props.dispatch).then(() => {
            let sales = [...this.state.sales];
            sales[this.state.selected_index] = {
                ...sales[this.state.selected_index],
                sale_status, sale_ship_no
            };
            this.setState({
                sales,
                modal_detail: false
            });
        });
    }

    getPage(page) {
        SalesApi.get(this.state.filter, page, this.props.dispatch)
            .then(({ sales, pg_current, pg_total, total }) => {
                this.setState({ sales, pg_current, pg_total, total });
            });
    }

    renderPagination() {
        let offset = (this.state.pg_current - 1) * PER_PAGE;
        let pg_current = Number(this.state.pg_current);
        let isFirstPage = pg_current === 1;
        let isLastPage = pg_current === this.state.pg_total;
        return <div className="pagination">
            Showing {offset + 1} - {Math.min(offset + PER_PAGE, this.state.total)} of {this.state.total}<br /><br />
            {this.state.pg_total > 1 && <div>{!isFirstPage && <a onClick={() => this.getPage(1)}>{"<<"}</a>}
                {!isFirstPage && <a onClick={() => this.getPage(pg_current - 1)}>{"<"}</a>}
                {!isFirstPage && <a onClick={() => this.getPage(pg_current - 1)}>{pg_current - 1}</a>}
                <a className="disabled">{pg_current}</a>
                {!isLastPage && <a onClick={() => this.getPage(pg_current + 1)}>{pg_current + 1}</a>}
                {!isLastPage && <a onClick={() => this.getPage(pg_current + 1)}>></a>}
                {!isLastPage && <a onClick={() => this.getPage(this.state.pg_total)}>>></a>}</div>}
        </div>;
    }

}

export default withRouter(connect(({ auth: { isSignedIn } }) => ({ isSignedIn }))(Sales));