import React, { Component } from "react";
import "../sass/component/modal-detail-item.scss";
import Modal from "../element/Modal";
import ToggleButton from "react-toggle-button";
import Dropdown from "react-dropdown";
import "react-dropdown/style.css";
import { connect } from "react-redux";
import moment from "moment";

class ModalEditItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            prd_name: props.data.prd_name,
            prd_desc: props.data.prd_desc,
            prd_ingr: props.data.prd_ingr,
            prdcat_id: props.data.prdcat_id,
            prd_variants: props.data.prd_variants,
            prdcode_group: props.data.prdcode_group
        };

        this.onClickBtnOK = this.onClickBtnOK.bind(this);

        this.edited = this.state.prd_variants.map(() => false);
        this.file = this.state.prd_variants.map(() => null);
        this.refInput = this.state.prd_variants.map(() => null);
    }

    componentWillReceiveProps(props) {
        if (!this.props.show && props.show) {
            let { prdcat_id, prdcat_name } = this.props.PRODUCT_CATEGORIES.find(({ prdcat_id }) => prdcat_id === props.data.prdcat_id);
            this.setState({
                prd_name: props.data.prd_name,
                prd_desc: props.data.prd_desc,
                prd_ingr: props.data.prd_ingr,
                prdcat: {
                    value: prdcat_id,
                    label: prdcat_name
                },
                prd_variants: props.data.prd_variants,
                status: props.data.status,
                prdcode_group: props.data.prdcode_group
            });

            this.refInput.forEach((row) => { row && (row.value = ""); });

            this.edited = this.state.prd_variants.map(() => false);
            this.file = this.state.prd_variants.map(() => null);
            this.refInput = this.state.prd_variants.map(() => null);
        }
    }

    onChangeImg(e, index_variant) {
        this.file[index_variant] = e.target.files[0];
        this.edited[index_variant] = true;

        let prd_variants = this.state.prd_variants;
        prd_variants[index_variant].prd_img_old = prd_variants[index_variant].prd_img_old || prd_variants[index_variant].prd_img;
        prd_variants[index_variant].prd_img_new = `prd_${moment().unix()}`;
        prd_variants[index_variant].prd_img = URL.createObjectURL(this.file[index_variant]);

        this.setState({ prd_variants });
    }

    onClickBtnOK() {
        if (!this.validateVariantName() || !this.validatePrdWeight()) {
            return;
        }

        let data = this.state;
        data.prdcat_id = this.state.prdcat.value;
        this.props.onClickBtnOK({
            prdcat_id: this.state.prdcat.value,
            prd_name: this.state.prd_name,
            prd_desc: this.state.prd_desc,
            prd_ingr: this.state.prd_ingr,
            prd_variants: this.state.prd_variants,
            prdcode_group: this.state.prdcode_group

        }, this.getImageFiles());
    }

    //For new product img temp id
    getImageFiles() {
        let file = {};
        for (let i = 0; i < this.edited.length; ++i) {
            let edited = this.edited[i];
            if (edited) {
                let img_id = this.state.prd_variants[i].prd_img_new;
                file[img_id] = this.file[i];
            }
        }
        return file;
    }

    validateVariantName() {
        let prd_variant_value = {};
        for (let { prd_variant = "" } of this.state.prd_variants) {
            prd_variant = prd_variant.trim().toLowerCase().replace(/^\w/, c => c.toUpperCase());
            if (prd_variant === "") {
                this.props.dispatch({
                    type: "SHOW_INFO",
                    payload: "Variant name cannot be empty"
                });
                return false;
            }
            if (prd_variant_value[prd_variant]) {
                this.props.dispatch({
                    type: "SHOW_INFO",
                    payload: `Duplicate variant name "${prd_variant}"`
                });
                return false;
            }
            prd_variant_value[prd_variant] = true;
        }
        return true;
    }

    validatePrdWeight() {
        for (let { prd_variant, prd_details } of this.state.prd_variants) {
            let prd_size_value = {};
            if (prd_details.length === 0) {
                this.props.dispatch({
                    type: "SHOW_INFO",
                    payload: `Please add at least 1 product of variant "${prd_variant}"`
                });
                return false;
            }
            for (let { prd_size = "" } of prd_details) {
                if (prd_size === "") {
                    this.props.dispatch({
                        type: "SHOW_INFO",
                        payload: "Product size cannot be empty"
                    });
                    return false;
                }
                if (prd_size_value[prd_size]) {
                    this.props.dispatch({
                        type: "SHOW_INFO",
                        payload: `Duplicate size "${prd_size.toLocaleString()}" in variant "${prd_variant}"`
                    });
                    return false;
                }
                prd_size_value[prd_size] = true;
            }
        }

        return true;
    }

    render() {
        return <Modal id="modal-edit-item"
            show={this.props.show}
            btn_ok="Save"
            btn_cancel="Cancel"
            title={"Item Detail"}
            onClickBtnOK={this.onClickBtnOK}
            onClickBtnCancel={this.props.onClickBtnCancel}>
            {this.renderCategory()}
            Name :<br />
            <textarea onChange={({ target: { value: prd_name } }) => this.setState({ prd_name })} value={this.state.prd_name} /><br /><br />
            Description :<br />
            <textarea onChange={({ target: { value: prd_desc } }) => this.setState({ prd_desc })} value={this.state.prd_desc} /><br /><br />
            Ingredients :<br />
            <textarea onChange={({ target: { value: prd_ingr } }) => this.setState({ prd_ingr })} value={this.state.prd_ingr} /><br />
            {this.renderVariant()}
            {this.renderAddVariant()}
        </Modal>;
    }

    renderTable(prd_details, index_variant) {
        return <div style={{ overflowX: "auto" }}><table className="table table-prd-detail">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>Size</th>
                    <th>Weight (gr)</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th>Stock</th>
                </tr>
            </thead>
            <tbody>
                {prd_details.map((row, i) => this.renderDetail(row, i, index_variant))}
            </tbody>
        </table></div>;
    }

    renderVariant() {
        let prd_variants = this.state.prd_variants;
        return prd_variants.map((row, index_variant) => {
            return <div className="variant-container">
                <div className="modal-item-variant">
                    <div style={{ textAlign: "center" }}>
                        <label htmlFor={`edit-item-td-${index_variant}`}>
                            <img className="modal-item-img-td" src={this.edited[index_variant] ? row.prd_img : `${process.env.REACT_APP_API_URL}${row.prd_img || "/assets/img/browse.png"}`} alt="Bali Asli" />
                        </label>
                        <input type="file" ref={(ref) => this.refInput[index_variant] = ref} onChange={(e) => this.onChangeImg(e, index_variant)} id={`edit-item-td-${index_variant}`} />
                    </div>
                    <div style={{ marginLeft: "1rem" }}>
                        Variant Name :<br /> <input onChange={(e) => this.onChangeVariant("prd_variant", e.target.value, index_variant)} value={row.prd_variant} />
                    </div>
                    {row.temp && <div className="variant-delete"><button onClick={() => this.deleteVariant(index_variant)} className="close">×</button></div>}
                </div>
                {this.renderTable(row.prd_details, index_variant)}
                {this.renderAddSize(index_variant)}
            </div>;
        });
    }

    onChangeVariant(name, value, index_variant) {
        let prd_variants = this.state.prd_variants;
        prd_variants[index_variant][name] = value;
        this.setState({ prd_variants });
    }

    addVariant() {
        let prd_variants = this.state.prd_variants;
        prd_variants.push({
            temp: true,
            prd_details: [{
                status: 1,
                prd_stock: 1
            }]
        });
        this.edited.push(false);
        this.file.push(null);
        this.refInput.push(null);
        this.setState({ prd_variants });
    }

    renderAddVariant() {
        return <div><button className="btn-add btn-add-variant" onClick={() => this.addVariant()}> Add New Variant</button></div>;
    }

    addSize(i) {
        let prd_variants = this.state.prd_variants;
        prd_variants[i].prd_details.push({
            status: 1,
            prd_stock: 1
        });
        this.setState({ prd_variants });
    }

    renderAddSize(i) {
        return <div style={{ paddingTop: "1rem" }}><button className="btn-add" onClick={() => this.addSize(i)}> Add New Size</button></div>;

    }

    deleteSize(index_variant, index_id) {
        let prd_variants = this.state.prd_variants;
        prd_variants[index_variant].prd_details.splice(index_id, 1);
        this.setState({ prd_variants });
    }

    deleteVariant(index_variant) {
        this.edited.splice(index_variant, 1);
        this.file.splice(index_variant, 1);
        this.refInput.splice(index_variant, 1);

        let prd_variants = this.state.prd_variants;
        prd_variants.splice(index_variant, 1);
        this.setState({ prd_variants });
    }

    onChangeDetail(name, value, index_id, index_variant) {
        let prd_variants = this.state.prd_variants;
        prd_variants[index_variant].prd_details[index_id][name] = value;
        this.setState({ prd_variants });
    }

    renderDetail({ prd_id, prd_size, prd_price, status, prd_stock, prd_weight }, index_id, index_variant) {
        return <tr key={`${index_id}`}>
            <td>{prd_id ? prd_id : <button type="button" className="close" style={{ fontSize: "2rem", color: "#860000" }} onClick={() => this.deleteSize(index_variant, index_id)}>×</button>}</td>
            <td><input onChange={({ target: { value } }) => this.onChangeDetail("prd_size", value, index_id, index_variant)} value={prd_size} /></td>
            <td><input onChange={({ target: { value } }) => this.onChangeDetail("prd_weight", value, index_id, index_variant)} value={prd_weight} type="number" /></td>
            <td><input onChange={({ target: { value } }) => this.onChangeDetail("prd_price", value, index_id, index_variant)} value={prd_price} type="number" /></td>
            <td>{this.renderToggleStatus(status, index_id, index_variant)}</td>
            <td>{this.renderToggleStock(prd_stock, index_id, index_variant)}</td>
        </tr>;
    }

    renderCategory() {

        return <div>
            Category :<br />
            <Dropdown value={this.state.prdcat} onChange={(prdcat) => this.setState({ prdcat })} options={this.props.PRODUCT_CATEGORIES.map(({ prdcat_id, prdcat_name }) => ({ value: prdcat_id, label: prdcat_name }))} placeholder="Select an option" />
            <br />
        </div>;
    }

    renderToggleStatus(status, index_id, index_variant) {
        return <ToggleButton
            containerStyle={{ display: "inline-flex", width: "100px" }}
            trackStyle={{ width: "100px" }}
            activeLabelStyle={{ width: "40px", paddingLeft: "40px" }}
            inactiveLabelStyle={{ width: "70px", paddingRight: "25px" }}
            thumbAnimateRange={[1, 80]}
            activeLabel="Active"
            inactiveLabel="Inactive"
            value={status === 1}
            onToggle={(status) => this.onChangeDetail("status", status ? 0 : 1, index_id, index_variant)} />;
    }

    renderToggleStock(prd_stock, index_id, index_variant) {
        return <ToggleButton
            containerStyle={{ display: "inline-flex", width: "100px" }}
            trackStyle={{ width: "100px" }}
            activeLabelStyle={{ width: "60px", marginRight: "-55px" }}
            inactiveLabelStyle={{ width: "70px", marginLeft: "10px" }}
            thumbAnimateRange={[1, 80]}
            activeLabel="In Stock"
            inactiveLabel="Out of Stock"
            value={prd_stock === 1}
            onToggle={(prd_stock) => this.onChangeDetail("prd_stock", prd_stock ? 0 : 1, index_id, index_variant)} />;
    }
}

ModalEditItem.defaultProps = {
    data: {
        prd_variants: [{
            prd_details: [{}]
        }]
    },
    onClickBtnOK: () => { },
    onClickBtnCancel: () => { }
};

export default connect(({ content: { PRODUCT_CATEGORIES } }) => ({ PRODUCT_CATEGORIES }))(ModalEditItem);