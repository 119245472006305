import request from "superagent";
import Helper from "./helper";

export default {
    getCities: (dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        request
            .get(`${process.env.REACT_APP_API_URL}/raja_ongkir/city`)
            .set("Accept", "application/json")
            .end((err, res) => {
                Helper.onResponse(dispatch);
                if (res && res.body.status=== "success") {
                    resolve(res.body.payload);
                } else {
                    Helper.onError(err, res, dispatch);
                }
            });
    }),
    getProvinces: (dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        request
            .get(`${process.env.REACT_APP_API_URL}/raja_ongkir/province`)
            .set("Accept", "application/json")
            .end((err, res) => {
                Helper.onResponse(dispatch);
                if (res && res.body.status=== "success") {
                    resolve(res.body.payload);
                } else {
                    Helper.onError(err, res, dispatch);
                }
            });
    }),
    getCosts: (query, dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        request
            .get(`${process.env.REACT_APP_API_URL}/raja_ongkir/cost`)
            .query(query)
            .set("Accept", "application/json")
            .end((err, res) => {
                Helper.onResponse(dispatch);
                if (res && res.body.status=== "success") {
                    resolve(res.body.payload);
                } else {
                    Helper.onError(err, res, dispatch);
                }
            });
    })
};