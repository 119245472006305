import React, { Component } from "react";
import "../sass/component/product-category.scss";
import Button from "../element/ButtonSlideUp";
import ModalProductCategory from "../component/ModalProductCategory";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { HOME_JAM, HOME_SOAP } from "../config/content";
import ButtonEdit from "../element/ButtonEdit";
import ContentApi from "../api/content";

class ProductCategory extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal_edit: false,
      selected_category: HOME_SOAP
    };

    this.onEdit = this.onEdit.bind(this);
    this.onEditJam = this.onEditJam.bind(this);
    this.onEditSoap = this.onEditSoap.bind(this);
  }

  onEditSoap() {
    this.setState({
      modal_edit: true,
      selected_category: HOME_SOAP
    });
  }

  onEditJam() {
    this.setState({
      modal_edit: true,
      selected_category: HOME_JAM
    });
  }

  render() {
    return <div className="pc-container">
      <div className="pc-item pc-soap" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}${this.props[HOME_SOAP].img})` }}>
        <ButtonEdit className="button-sm btn-banner" onClick={this.onEditSoap} />
        <div className="pc-item-desc">
          <div className="pc-item-title">{this.props[HOME_SOAP].title}</div>
          <div className="pc-item-subtitle">{this.props[HOME_SOAP].desc}</div>
        </div>
        <div className="pc-item-btn">
          {this.props[HOME_SOAP].url && <Button className="button-sm" onClick={() => this.props.history.push(this.props[HOME_SOAP].url)}>{this.props[HOME_SOAP].button_text}</Button>}
        </div>
      </div>

      <div className="pc-item pc-jam" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}${this.props[HOME_JAM].img})` }}>
        <ButtonEdit className="button-sm btn-banner" onClick={this.onEditJam} />
        <div className="pc-item-desc">
          <div className="pc-item-title">{this.props[HOME_JAM].title}</div>
          <div className="pc-item-subtitle">{this.props[HOME_JAM].desc}</div>
        </div>
        <div className="pc-item-btn">
          {this.props[HOME_JAM].url && <Button className="button-sm" onClick={() => this.props.history.push(this.props[HOME_JAM].url)}>{this.props[HOME_JAM].button_text}</Button>}
        </div>
      </div>
      <ModalProductCategory show={this.state.modal_edit} data={this.props[this.state.selected_category]} onClickBtnOK={this.onEdit} onClickBtnCancel={() => this.setState({ modal_edit: false })} />
    </div>;
  }

  onEdit(title, desc, url, button_text, file_bg) {

    this.props.dispatch({
      type: "SHOW_CONFIRM",
      payload: "Are you sure you want to save changes ?",
      callback: () => ContentApi.updateWithFiles({ cten_name: this.state.selected_category, cten_value: { title, desc, url, button_text }, file_bg }, this.props.dispatch)
        .then((data) => {
          this.setState({ modal_edit: false });
          this.props.dispatch({ type: "SHOW_INFO", payload: "Data sucessfully updated" });
          this.props.dispatch({ type: "UPDATE_CONTENTS", payload: { [this.state.selected_category]: data } });
        })
    });
  }
}

ProductCategory.defaultProps = {
  [HOME_JAM]: {},
  [HOME_SOAP]: {}
};

export default withRouter(connect(({ content }) => ({
  [HOME_JAM]: content[HOME_JAM],
  [HOME_SOAP]: content[HOME_SOAP]
}))(ProductCategory));