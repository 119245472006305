import React, { Component } from "react";
import "../sass/component/modal-detail-item.scss";
import Modal from "../element/Modal";
import { FOOTER_CONTACT } from "../config/content";

class ModalEditContact extends Component {

    constructor(props) {
        super(props);

        this.state = {
            phone: "",
            whatsapp: "",
            email: ""
        };

        this.onClickBtnOK = this.onClickBtnOK.bind(this);
    }

    componentWillReceiveProps(props) {
        if (!this.props.show && props.show) {
            this.setState({
                phone: props[FOOTER_CONTACT].phone,
                whatsapp: props[FOOTER_CONTACT].whatsapp,
                email: props[FOOTER_CONTACT].email
            });
        }
    }

    onClickBtnOK() {
        this.props.onClickBtnOK(FOOTER_CONTACT, this.state);
    }

    render() {
        return <Modal id="modal-edit-contact"
            show={this.props.show}
            btn_ok="Save"
            btn_cancel="Cancel"
            title={"Edit Contact"}
            onClickBtnOK={this.onClickBtnOK}
            onClickBtnCancel={this.props.onClickBtnCancel}>
            Phone :<br />
            <textarea value={this.state.phone} onChange={({ target: { value: phone } }) => this.setState({ phone })} /><br /><br />
            Whatsapp :<br />
            <textarea value={this.state.whatsapp} onChange={({ target: { value: whatsapp } }) => this.setState({ whatsapp })} /><br /><br />
            Email :<br />
            <textarea value={this.state.email} onChange={({ target: { value: email } }) => this.setState({ email })} /><br /><br />
        </Modal>;
    }
}

ModalEditContact.defaultProps = {
    data: {},
    onClickBtnOK: () => { },
    onClickBtnCancel: () => { }
};

export default ModalEditContact;