import React, { Component } from "react";
import Header from "../component/Header";
import Footer from "../component/Footer";

class Page extends Component {

    render() {
        return <div>
            <Header />
            <div className="section-root">
                {this.props.children}
            </div>
            <Footer />
        </div>;
    }
}

export default Page;