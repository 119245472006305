import React, { Component } from "react";
import "../sass/component/navigation-mobile.scss";
import Html from "../helper/html";
import { withRouter } from "react-router";
import { connect } from "react-redux";

class Navigation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dropdown_shop: 0
        };

        this.onClickShop = this.onClickShop.bind(this);
        this.onClickLiShop = this.onClickLiShop.bind(this);

    }

    componentDidMount() {
        this.unlisten = this.props.history.listen(({ pathname, hash }) => {
            if (pathname === "/" && hash === "#visit") {
                setTimeout(() => {
                    Html.scrollTo("visit");
                }, 100);
            } else if (pathname === "/" && hash === "#contact") {
                setTimeout(() => {
                    Html.scrollTo("footer");
                }, 100);
            } else {
                window.scrollTo(0, 0);
            }

        });

    }

    componentWillUnmount() {
        this.unlisten();
    }

    onClickShop() {
        this.setState({ dropdown_shop: !this.state.dropdown_shop });
    }

    onClickLi(path) {
        this.props.history.push(path);
        this.props.onClick();
    }

    onClickLiShop({ prdcat_id = 0, prdcat_parent_id = -1 }) {
        this.props.history.push(`/shop/${prdcat_id}`);
        this.props.onClick();
    }

    renderCategories() {
        let prdct_map = this.props.data[0] || [];
        return prdct_map.map((row) => {
            let el = <li key={row.prdcat_id} onClick={() => this.onClickLiShop(row)}><a>{row.prdcat_name}</a></li>;
            let children = (this.props.data[row.prdcat_id] || []).map((row) => <li key={row.prdcat_id} onClick={() => this.onClickLiShop(row)} className="child-1"><a>{row.prdcat_name}</a></li>);
            return [el, ...children];
        });
    }

    render() {
        return <div className={`navm-wrapper ${this.props.show ? "show" : ""}`}>
            <nav>
                <ul>
                    <li>
                        <div className="navm-name" onClick={this.onClickShop}>Shop<i className="icon-caret-down" /></div>
                        <div className="navm-relative">
                            <ul style={{ display: this.state.dropdown_shop ? "block" : "none" }}>
                                {this.renderCategories()}
                            </ul>
                        </div>
                    </li>
                    <li onClick={() => this.onClickLi("/about")}>
                        <div className="navm-name">About</div>
                    </li>
                    <li onClick={() => this.onClickLi("/#contact")}>
                        <div className="navm-name">Contact</div>
                    </li>
                    <li onClick={() => this.onClickLi("/#contact")}>
                        <div className="navm-name">Visit</div>
                    </li>
                    {!this.props.isSignedIn && <li onClick={() => this.onClickLi("/cart")}>
                        <div className="navm-name">Shopping Cart</div>
                    </li>}
                    {this.props.isSignedIn && <li onClick={() => this.onClickLi("/sales")}>
                        <div className="navm-name">Orders</div>
                    </li>}
                    {this.props.isSignedIn && <li onClick={() => this.onLogout()}>
                        <div className="navm-name">Logout</div>
                    </li>}
                </ul>
            </nav>
        </div>;
    }

    onLogout() {
        this.props.dispatch({
            type: "SHOW_CONFIRM",
            payload: "Are you sure you want to logout ?",
            callback: () => this.props.dispatch({ type: "SIGNED_OUT" })
        });
    }
}

Navigation.defaultProps = {
    data: []
};

export default withRouter(connect(({ auth: { isSignedIn }, cart: { cart } }) => {
    let cart_qty = 0;
    for (let prdcat_id in cart) {
        cart_qty += cart[prdcat_id].qty;
    }
    return {
        cart_qty,
        isSignedIn
    };
})(Navigation));