import React, { Component } from "react";
import "../sass/component/navigation.scss";
import Html from "../helper/html";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { FACEBOOK, INSTAGRAM } from "../config/content";

class Navigation extends Component {

    constructor(props) {
        super(props);

        this.state = {
            dropdown_shop: 0,
            dropdown_social: 0
        };

    }

    componentDidMount() {
        this.unlisten = this.props.history.listen(({ pathname, hash }) => {
            if (pathname === "/" && hash === "#visit") {
                setTimeout(() => {
                    Html.scrollTo("visit");
                }, 100);
            } else if (pathname === "/" && hash === "#contact") {
                setTimeout(() => {
                    Html.scrollTo("footer");
                }, 100);
            } else {
                window.scrollTo(0, 0);
            }

        });

    }

    componentWillUnmount() {
        this.unlisten();
    }

    showDropdownShop(opacity) {
        this.setState({
            dropdown_shop: opacity
        });
    }

    showDropdownSocial(opacity) {
        this.setState({
            dropdown_social: opacity
        });
    }

    onClickAnchor({ prdcat_id = 0 }) {
        this.showDropdownShop(0);
        this.props.history.push(`/shop/${prdcat_id}`);
    }

    isActive(url) {

        let { pathname, hash } = this.props.location;
        let current_url = pathname; // + hash
        return current_url.match(`${url}/*`) ? "active" : "";
    }

    renderCategories() {
        let prdct_map = this.props.data[0] || [];
        return prdct_map.map((row) => {
            let el = <li key={row.prdcat_id}><a onClick={() => this.onClickAnchor(row)}>{row.prdcat_name}</a></li>;
            let children = (this.props.data[row.prdcat_id] || []).map((row) => <li key={row.prdcat_id} className="child-1"><a onClick={() => this.onClickAnchor(row)}>{row.prdcat_name}</a></li>);
            return [el, ...children];
        });
    }

    render() {
        return <div className="nav-wrapper">
            <nav>
                <ul>
                    {/* <li className={this.isActive("/")} onClick={() => this.props.history.push("/")}>
                        <div className="nav-name">Home</div>
                    </li> */}
                    <li className={this.isActive("/shop")} onMouseLeave={() => this.showDropdownShop(0)}>
                        <div className="nav-name" onClick={() => this.onClickAnchor({ prdcat_id: 0, prdcat_parent_id: 0 })} onMouseEnter={() => this.showDropdownShop(1)}>Shop</div>
                        <div className="nav-relative">
                            <ul className={`nav-absolute ${this.state.dropdown_shop ? "nav-absolute-show" : ""}`}>
                                {this.renderCategories()}
                            </ul>
                        </div>
                    </li>
                    <li className={this.isActive("/about")} onClick={() => this.props.history.push({ pathname: "/about", state: { test: 1 } })}>
                        <div
                            className="nav-name">About</div>
                    </li>
                    <li className={this.isActive("/#contact")} onClick={() => this.props.history.push("/#contact")}>
                        <div className="nav-name">Contact</div>

                    </li>
                    <li className={this.isActive("/#visit")} onClick={() => this.props.history.push("/#contact")}>
                        <div className="nav-name">Visit</div>
                    </li>
                    <li onMouseLeave={() => this.showDropdownSocial(0)}>
                        <div className="nav-name" onMouseEnter={() => this.showDropdownSocial(1)}>Follow Us</div>
                        <div className="nav-relative">
                            <ul className={`nav-absolute ${this.state.dropdown_social ? "nav-absolute-show" : ""}`}>
                                <li><a target="_blank" rel="noopener noreferrer" href={this.props[FACEBOOK].url}>Facebook</a></li>
                                <li><a target="_blank" rel="noopener noreferrer" href={this.props[INSTAGRAM].url}>Instagram</a></li>
                            </ul>
                        </div>
                    </li>
                    {this.props.isSignedIn && <li className={this.isActive("/sales")} onClick={() => this.props.history.push("/sales")}>
                        <div className="nav-name">Orders</div>
                    </li>}
                    {/* this.props.isSignedIn && <li onClick={() => this.onLogout()}>
                        <div className="nav-name"><i style={{ fontSize: '1.4rem' }} className="icon-switch"></i></div>
                </li>*/}
                </ul>
            </nav>
        </div>;
    }

    onLogout() {
        this.props.dispatch({
            type: "SHOW_CONFIRM",
            payload: "Are you sure you want to logout ?",
            callback: () => {
                this.props.dispatch({ type: "SIGNED_OUT" });
            }
        });
    }
}

Navigation.defaultProps = {
    data: {}
};

export default withRouter(connect(({ auth: { isSignedIn }, cart: { cart }, content }) => {
    let cart_qty = 0;
    for (let prdcat_id in cart) {
        cart_qty += cart[prdcat_id].qty;
    }
    return {
        cart_qty,
        isSignedIn,
        [FACEBOOK]: content[FACEBOOK],
        [INSTAGRAM]: content[INSTAGRAM],
    };
})(Navigation));
