export default {
  onError: (err, res, dispatch) => {
    if (!dispatch) {
    } else if (res && res.status === 401) {
      dispatch({
        type: "SIGNED_OUT",
      });
      dispatch({
        type: "SHOW_INFO",
        payload: "Session expired, please login again",
        callback: () => (window.location = "/admin/login"),
      });
    } else if (res.status === 400) {
      dispatch({
        type: "SIGNED_OUT",
      });
      dispatch({
        type: "SHOW_INFO",
        payload: res.body.message || "",
      });
    } else if (res && res.body.status === "error") {
      dispatch({
        type: "SHOW_INFO",
        payload: res.body.payload.error,
      });
    } else {
      dispatch({
        type: "SHOW_INFO",
        payload: err.message,
      });
    }
  },
  onRequest: (dispatch) => {
    if (dispatch) {
      dispatch({
        type: "SHOW_LOADER",
      });
    }
  },
  onResponse: (dispatch) => {
    if (dispatch) {
      setTimeout(() => {
        dispatch({
          type: "HIDE_LOADER",
        });
      }, 300);
    }
  },
  JSONparse: (str) => {
    try {
      return JSON.parse(str);
    } catch (e) {
      return str;
    }
  },
};
