import React, { Component } from "react";
import "../sass/page/about.scss";
import Separator from "../element/Separator";
import ContentApi from "../api/content";
import { connect } from "react-redux";
import Page from "./Page";
import ModalEditAbout from "../component/ModalEditAbout";
import { ABOUT_US } from "../config/content";
import ButtonEdit from "../element/ButtonEdit";
import ReactHtmlParser from "react-html-parser";

class About extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal_edit: false
        };

        this.onEdit = this.onEdit.bind(this);
    }

    componentWillMount() {
        document.title = "Bali Asli - About Us";
        ContentApi.getAbout(this.props.dispatch).then((data) => {
            this.props.dispatch({
                type: "UPDATE_CONTENTS",
                payload: {
                    [ABOUT_US]: data
                }
            });
        });
        this.props.dispatch({
            type: "PAGE_VIEW",
            payload: "/about"
        });
    }

    render() {
        return <Page>
            <div className="abt-container">
                <ButtonEdit className="button-sm btn-banner" onClick={() => this.setState({ modal_edit: true })} />
                <div className="abt-img">
                    <img alt="bali asli" src={`${process.env.REACT_APP_API_URL}${this.props.data.img}`} />
                </div>
                <div className="abt-text">
                    <div className="ba-title">{this.props.data.title}</div>
                    <br />
                    <br />
                    <div>{ ReactHtmlParser (this.props.data.desc) }</div>
                </div>
            </div>
            <Separator />
            <ModalEditAbout data={this.props.data} show={this.state.modal_edit} onClickBtnOK={this.onEdit} onClickBtnCancel={() => this.setState({ modal_edit: false })} />
        </Page>;
    }

    onEdit(title, desc, file) {

        this.props.dispatch({
            type: "SHOW_CONFIRM",
            payload: "Are you sure you want to save changes ?",
            callback: () => ContentApi.updateAbout(title, desc, file, this.props.dispatch).then((data) => {
                this.props.dispatch({ type: "SHOW_INFO", payload: "Data sucessfully updated" });
                this.props.dispatch({ type: "UPDATE_CONTENTS", payload: { [ABOUT_US]: data } });
                this.setState({ modal_edit: false });
            })
        });

    }

}

export default connect(({ content: { [ABOUT_US]: data } }) => ({ data }))(About);