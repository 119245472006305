import Regex from "../config/regex";

export default {
  validateInfo: ({
    cnt_name,
    cnt_email,
    cnt_address_1,
    cnt_city,
    cnt_province,
    cnt_postal_code,
    cnt_phone,
  }) =>
    new Promise((resolve, reject) => {
      if (!cnt_name) {
        reject({ type: "error_msg", msg: "Please fill in name" });
      } else if (!cnt_email) {
        reject({ type: "error_msg", msg: "Please fill in email" });
      } else if (!Regex.email(cnt_email)) {
        reject({ type: "error_msg", msg: "Invalid email" });
      } else if (!cnt_address_1) {
        reject({ type: "error_msg", msg: "Please fill in address" });
      } else if (!cnt_province.province_id) {
        reject({ type: "error_msg", msg: "Please select a province" });
      } else if (!cnt_city.city_id) {
        reject({ type: "error_msg", msg: "Please select a city" });
      } else if (!cnt_postal_code) {
        reject({ type: "error_msg", msg: "Please fill in postal code" });
      } else if (!Regex.number(cnt_postal_code)) {
        reject({ type: "error_msg", msg: "Invalid postal code" });
      } else if (!cnt_phone) {
        reject({ type: "error_msg", msg: "Please fill in phone number" });
      } else {
        resolve(true);
      }
    }),
};
