export default function reducer(
  state = {
    jwt: localStorage.getItem("jwt"),
    isSignedIn: localStorage.getItem("jwt") ? true : false,
    admName: localStorage.getItem("adm_name"),
  },
  { type, payload }
) {
  switch (type) {
    case "SIGNED_IN": {
      let { token, adm_name } = payload;
      localStorage.setItem("jwt", token);
      localStorage.setItem("adm_name", adm_name);
      return { ...state, jwt: token, isSignedIn: true, admName: adm_name };
    }
    case "SIGNED_OUT": {
      localStorage.removeItem("jwt");
      return {
        ...state,
        jwt: undefined,
        admName: undefined,
        isSignedIn: false,
      };
    }
    case "UPDATE_ADM_NAME": {
      return { ...state, usrName: payload };
    }
    default:
      return state;
  }
}
