import React, { Component } from "react";
import "../sass/page/news.scss";
import NewsApi from "../api/news";
import NewsItem from "../component/NewsItem";
import NewsLinkItem from "../component/NewsLinkItem";
import Separator from "../element/Separator";
import { connect } from "react-redux";
import Page from "./Page";
import { withRouter } from "react-router-dom";
import ModalEditNews from "../component/ModalEditNews";

class News extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: [{}],
            selected_index: 0,
            edit_data: {}
        };

        this.openEdit = this.openEdit.bind(this);
        this.closeEdit = this.closeEdit.bind(this);

        this.onEdit = this.onEdit.bind(this);
    }

    componentWillMount() {
        document.title = "Bali Asli - News & Updates";
        NewsApi.getNews().then((data) => {
            let selected_index = data.findIndex(({ news_id }) => this.props.match.params.news_id === news_id);
            this.setState({
                data,
                selected_index: selected_index === -1 ? 0 : selected_index
            });
        });
        this.props.dispatch({
            type: "PAGE_VIEW",
            payload: "/news"
        });
    }

    componentWillReceiveProps(props) {
        if (props.match.params.news_id !== this.props.match.params.news_id) {
            let selected_index = this.state.data.findIndex(({ news_id }) => props.match.params.news_id === news_id);
            this.setState({
                selected_index: selected_index === -1 ? 0 : selected_index
            });
        }
    }

    renderLatestPost() {
        let news_link_item = [];
        let { data } = this.state;
        if (data.length > 1) {
            for (let i = 0; i < data.length; ++i) {
                if (i !== this.state.selected_index) {
                    news_link_item.push(<NewsLinkItem onClickDelete={() => this.onDelete(i)} onClickEdit={() => this.openEdit(i)} onClick={() => this.props.history.push(`/news/${data[i].news_id}`)} data={data[i]} />);
                }
            }
        }
        return news_link_item;
    }

    openEdit(edit_index) {
        this.setState({ modal_edit: true, edit_index });
    }

    closeEdit() {
        this.setState({ modal_edit: false });
    }

    render() {
        return <Page><div className="news-container">
            <div className="news-item-container">
                <NewsItem data={this.state.data[this.state.selected_index]}
                    onClickAdd={() => this.openAdd()}
                    onClickEdit={() => this.openEdit(this.state.selected_index)}
                    onClickDelete={() => this.onDelete(this.state.selected_index)} />
            </div>

            {this.state.data.length > 1 && <div className="news-link-container">
                <div className="ba-title nli-title">Latest Post</div>
                {this.renderLatestPost()}
            </div>}
        </div>
            <Separator />
            <ModalEditNews onClickBtnOK={this.onEdit} onClickBtnCancel={this.closeEdit} data={this.state.data[this.state.edit_index]} show={this.state.modal_edit} />
        </Page>;
    }

    openAdd() {
        this.setState({ modal_edit: true, edit_index: -1 });
    }

    onEdit(news, file) {
        if (this.state.edit_index === -1) {
            this.onAdd(news, file);
        } else {

            this.props.dispatch({
                type: "SHOW_CONFIRM",
                payload: "Are you sure you want to save item ?",
                callback: () => NewsApi.update(news, file, this.props.dispatch).then((updated_news) => {
                    let data = [...this.state.data];
                    data[this.state.edit_index] = {
                        ...data[this.state.edit_index],
                        ...updated_news
                    };
                    this.setState({
                        data,
                        modal_edit: false
                    });
                    this.props.dispatch({ type: "SHOW_INFO", payload: "Data sucessfully updated" });
                })
            });

        }
    }

    onAdd(news, file) {

        this.props.dispatch({
            type: "SHOW_CONFIRM",
            payload: "Are you sure you want to submit ?",
            callback: () => NewsApi.add(news, file, this.props.dispatch).then((created_news) => {
                let data = this.state.data;
                data.unshift(created_news);
                this.setState({
                    data,
                    modal_edit: false
                });
                this.props.dispatch({ type: "SHOW_INFO", payload: "Data sucessfully updated" });
            })
        });

    }

    onDelete(index) {

        this.props.dispatch({
            type: "SHOW_CONFIRM",
            payload: "Are you sure you want to delete ?",
            callback: () => NewsApi.delete(this.state.data[index].news_id, this.props.dispatch).then(() => {

                let data = [...this.state.data];
                data.splice(index, 1);
                let selected_index = data.findIndex(({ news_id }) => this.props.match.params.news_id === news_id);
                this.setState({
                    data,
                    selected_index: selected_index === -1 ? 0 : selected_index
                }, () => {
                    if (index === this.state.selected_index) {
                        this.props.history.replace("/news");
                    }
                });

            })
        });


    }
}

export default withRouter(connect(() => ({}))(News));