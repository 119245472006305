import {combineReducers} from "redux";
import auth from "./auth";
import loader from "./loader";
import info from "./info";
import cart from "./cart";
import content from "./content";
import confirm from "./confirm";
import analytics from "./analytics";

export default combineReducers({
    loader,
    auth,
    info,
    confirm,
    cart,
    content,
    analytics
});
