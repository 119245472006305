import React, { Component } from "react";
import "../sass/page/about.scss";
import Modal from "../element/Modal";
import Wysiwyg from "../component/Wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import WysiwygHelper from "../helper/wysiwyg";

class ModalEditContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: "",
            desc: "",
            img: ""
        };

        this.onClickBtnOK = this.onClickBtnOK.bind(this);
    }

    componentWillReceiveProps(props) {
        if (!this.props.show && props.show) {
            this.setState({
                ...props.data,
                editorState: WysiwygHelper.stringToEditorState(props.data.desc)
            });
            this.refInput && (this.refInput.value="");
            this.edited = false;
            this.file = null;
            this.refInput = null;
        }
    }

    onChangeLogo(e) {
        this.file = e.target.files[0];
        this.edited = true;
        this.setState({ img: URL.createObjectURL(this.file) });
    }

    onClickBtnOK() {

        this.props.onClickBtnOK(this.state.title, WysiwygHelper.editorStateToString(this.state.editorState), this.file);
    }

    render() {
        return <Modal id="modal-edit-about"
            show={this.props.show}
            btn_ok="Save"
            btn_cancel="Cancel"
            title={"Edit About Us"}
            onClickBtnOK={this.onClickBtnOK}
            onClickBtnCancel={this.props.onClickBtnCancel}>
            <div style={{ textAlign: "center" }}>
                <label htmlFor={"edit-about"}>
                    <img className="modal-abt-img" src={this.edited ? this.state.img : `${process.env.REACT_APP_API_URL}${this.state.img}`} alt="Bali Asli" />
                </label>
                <input type="file" ref={(ref) => this.refInput = ref} onChange={(e) => this.onChangeLogo(e)} id={"edit-about"} />
            </div>
            <br />
            Title:<br />
            <textarea rows="2" onChange={({ target: { value: title } }) => this.setState({ title })} value={this.state.title}>{this.state.title}</textarea>
            <br /><br />Descrpition:<br />
            <Wysiwyg
                editorState={this.state.editorState}
                onEditorStateChange={(editorState) => {
                    this.setState({
                        editorState,
                    });
                }}/>
        </Modal>;
    }
}

ModalEditContent.defaultProps = {
    data: {},
    onClickBtnOK: () => { },
    onClickBtnCancel: () => { }
};

export default ModalEditContent;