import React, { Component } from "react";
import "../sass/element/separator.scss";

class Separator extends Component {
  render() {
    return <div className="separator" style={{height: this.props.height}} />;
  }
}

Separator.defaultProps = {
  height: "2rem"
};

export default Separator;