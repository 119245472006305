import React, { Component } from "react";
import "../sass/component/shop-item.scss";
import "../sass/corner-ribbon.scss";
import Button from "../element/ButtonSlideUp";
import { connect } from "react-redux";

class ShopItem extends Component {
    renderStatus() {

        if (this.props.status === 0) {
            return <div className="si-status">
                <div style={{ color: "#bb3333" }}>Inactive</div>
            </div>;
        } else if (this.props.prd_stock === 0) {
            return <div className="si-status">
                <div style={{ color: "gray" }}>Out of Stock</div>
            </div>;
        }
    }

    render() {
        return <div className={`gallery-block-two mix all ${this.props.className} col-lg-4 col-md-4 col-sm-6 col-xs-12`}>
            <div className="inner-box" style={{ padding: "5px" }}>
                {this.renderStatus()}
                <div style={{ position: "relative" }}>
                    <img key={this.props.key} alt="" onLoadStart={() => console.log(this.props.prd_name)} style={{
                        width: "100%",
                        height: "auto"
                    }} src={`${process.env.REACT_APP_API_URL}${this.props.img}`} />
                    <div className="si-add-cart">
                        {this.props.showEdit && this.props.isSignedIn && <a className="si-add-edit" onClick={this.props.onEdit}><img alt="" style={{ width: "3rem", paddingTop: ".5rem" }} src={`${process.env.PUBLIC_URL}/images/edit-solid.svg`} /></a>}
                        {(this.props.prd_stock !== 0 && !this.props.isSignedIn) && <Button onClick={this.props.onClick}>See Details</Button>}
                    </div>
                </div>
            </div>
            <div className="si-desc-container">
                <div className="si-desc" style={{ position: "absolute" }}>
                    <div>{this.props.title}</div>
                    <div>{this.props.price ? `IDR ${this.props.price}` : ""}</div>
                </div>
            </div>
        </div>;
    }
}

ShopItem.defaultProps = {
    showEdit: true
};

export default connect(({ auth: { isSignedIn } }) => ({ isSignedIn }))(ShopItem);