import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

export default {
    stringToEditorState: (htmlString) => {
        let { contentBlocks, entityMap } = htmlToDraft(htmlString);
        let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        return EditorState.createWithContent(contentState);
    },
    editorStateToString: (editorState) => {
        return draftToHtml(convertToRaw(editorState.getCurrentContent()));
    }
};