import React, { Component } from "react";
import "../sass/component/modal-detail-item.scss";
import Modal from "../element/Modal";
import { SCHEDULE_VISIT } from "../config/content";

class ModalEditVisit extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: props.title,
            desc: props.desc,
            email: props.email
        };

        this.onClickBtnOK = this.onClickBtnOK.bind(this);
    }

    componentWillReceiveProps(props) {
        if (!this.props.show && props.show) {
            this.setState({
                email: props.email,
                desc: props.desc,
                title: props.title
            });

            this.edited_bg = false;
            this.file_bg = false;
            
            if (this.refInputBG) {
                this.refInputBG.value = "";
            }
        }
    }

    onClickBtnOK() {
        // this.props.onClickBtnOK(this.state.title, this.state.desc, this.state.email, this.file_bg)
        this.props.onClickBtnOK(SCHEDULE_VISIT, {
            title: this.state.title,
            desc:this.state.desc,
            email:this.state.email
        });
    }


    onChangeLogo(e) {

        this.file_bg = e.target.files[0];
        this.edited_bg = true;

        this.setState({ img: URL.createObjectURL(this.file_bg) });

    }

    render() {
        return <Modal id="modal-edit-visit"
            show={this.props.show}
            btn_ok="Save"
            btn_cancel="Cancel"
            title={"Edit Schedule Visit"}
            onClickBtnOK={this.onClickBtnOK}
            onClickBtnCancel={this.props.onClickBtnCancel}>
            {/* <div style={{ textAlign: "center" }}>
                <label htmlFor={`edit-sv-bg`}>
                    <img style={{ width: "40rem" }} src={this.edited_bg ? this.state.img : `${process.env.REACT_APP_API_URL}${this.props.img}`} alt="Bali Asli"></img>
                </label>
                <input type="file" ref={(ref) => this.refInputBG = ref} onChange={(e) => this.onChangeLogo(e)} id={`edit-sv-bg`} />
            </div>
            <br /> */}
            Title :<br />
            <textarea onChange={({ target: { value: title } }) => this.setState({ title })} value={this.state.title} /><br /><br />
            Description :<br />
            <textarea rows="5" onChange={({ target: { value: desc } }) => this.setState({ desc })} value={this.state.desc} /><br /><br />
            Send to :<br />
            <textarea onChange={({ target: { value: email } }) => this.setState({ email })} value={this.state.email} /><br /><br />
        </Modal>;
    }
}

ModalEditVisit.defaultProps = {
    data: {},
    onClickBtnOK: () => { },
    onClickBtnCancel: () => { }
};

export default ModalEditVisit;