import React, { Component } from "react";
import "../sass/page/home.scss";
import "../sass/component/schedule-visit.scss";
import Slider from "../component/Slider";
import AvailableAt from "../component/AvailableAt";
import WholeSale from "../component/WholeSale";
import ProductCategory from "../component/ProductCategory";
import Separator from "../element/Separator";
import ContentApi from "../api/content";
import { connect } from "react-redux";
import { SLIDER, AVAILABLE_AT, HOME_JAM, HOME_SOAP, INSTAGRAM, SCHEDULE_VISIT, WHOLESALE_ORDER } from "../config/content";
import Page from "./Page";
import BestSeller from "../component/BestSeller";

class Home extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount() {
    let cten_names = [SLIDER, AVAILABLE_AT, HOME_JAM, HOME_SOAP, INSTAGRAM, SCHEDULE_VISIT, WHOLESALE_ORDER];
    ContentApi.getContents(cten_names, this.props.dispatch)
      .then((contents) => this.props.dispatch({ type: "UPDATE_CONTENTS", payload: contents }));
    this.props.dispatch({
      type: "PAGE_VIEW",
      payload: "/"
    });
  }

  render() {
    return <Page>
      <Slider /> <Separator />
      <ProductCategory /> <Separator />
      {/* <ScheduleVisit/> <Separator/> */}
      <BestSeller /> <Separator />
      <WholeSale /> <Separator />
      {/* <Instagram/> <Separator/> */}
      <AvailableAt /> <Separator />
    </Page>;
  }
}

export default connect(() => ({}))(Home);