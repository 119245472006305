import React, { Component } from "react";
import "../sass/page/cart.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import CartItem from "../component/CartItem";
import Button from "../element/ButtonSlideUp";
import Separator from "../element/Separator";
import Page from "./Page";

class Cart extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isEmpty: true
        };
    }

    componentWillMount(){
        document.title = "Bali Asli - Shopping Cart";
        this.props.dispatch({
            type: "PAGE_VIEW",
            payload: "/cart"
        });
    }

    renderCartItem() {
        let items = Object.values(this.props.cart);
        if (items.length > 0) {
            this.state.isEmpty && this.setState({isEmpty: false});
            return items.map((row, i) => <CartItem key={i} data={row} />);
        } else {
            !this.state.isEmpty && this.setState({isEmpty: true});
            return <div style={{ textAlign: "center" }}>Your cart is currently empty</div>;
        }
    }

    countSubtotal() {
        let subtotal = 0;
        for (let prd_id in this.props.cart) {
            let { qty, prd_price } = this.props.cart[prd_id];
            subtotal += qty * prd_price;
        }
        return subtotal;
    }

    renderSubtotal() {
        return <div className="cart-subtotal">
            <div style={{fontSize:"1.4rem"}}>Shipment fee will be calculated at checkout</div>
            <div style={{ flexGrow:1 }}><b>Subtotal</b></div>
            <div style={{ width: "20%" }}><b>IDR {this.countSubtotal().toLocaleString()}</b></div>
        </div>;
    }

    renderButtons(){
        return <div className="button-group">
            <Button onClick={() => this.props.history.push("/shop")}>Continue Shopping</Button>
            <Button onClick={() => this.props.history.push("/checkout")}>Checkout&nbsp;<i className="icon-caret-right" /></Button>
        </div>;
    }

    render() {
        return <Page>
            <div className="cart-container">
            <div className="ba-title">Shopping Cart</div>
            <div className="cart-list">
                {this.renderCartItem()}
                {!this.state.isEmpty && this.renderSubtotal()}
                {!this.state.isEmpty && this.renderButtons()}
            </div>
        </div><Separator />
        </Page>;
    }
}

Cart.defaultProps = {
    data: {}
};

export default withRouter(connect(({ cart }) => cart)(Cart));