import React, { Component } from "react";
import { connect } from "react-redux";
import "../sass/page/login-admin.scss";
import AuthApi from "../api/auth";
import { withRouter } from "react-router-dom";
import ButtonSlideUp from "../element/ButtonSlideUp";
import ErrorMsg from "../component/ErrorMsg";

class LoginAdmin extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: "",
            password: "",
        };

        this.onClickLogin = this.onClickLogin.bind(this);
    }

    onClickLogin() {
        this.setState({ error_msg: "" });

        AuthApi.login(this.state, this.props.dispatch)
            .then((data) => {
                this.props.dispatch({
                    type: "SIGNED_IN",
                    payload: data
                });
                this.props.history.push("/");
            })
            .catch((error_msg) => this.setState({ error_msg }));
    }

    render() {
        return <div className="login-container">
            <header>
                <img src={process.env.REACT_APP_API_URL + "/assets/img/logo.png"} alt="Bali Asli Logo" />
            </header>
            <br />
            {this.state.error_msg ? <ErrorMsg>* {this.state.error_msg}</ErrorMsg> : <br />}
            <div>Username</div>
            <input className="login-input-username" onChange={(e) => this.setState({username: e.target.value})} value={this.state.username} />
            <div>Password</div>
            <input value={this.state.password} type="password" onChange={(e) => this.setState({password: e.target.value})} />
            <ButtonSlideUp onClick={this.onClickLogin}>Login</ButtonSlideUp>
        </div>;
    }
}

export default withRouter(connect(() => ({}))(LoginAdmin));