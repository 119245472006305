import React, { Component } from "react";
import "../sass/component/modal-best-seller.scss";
import Modal from "../element/Modal";
import Button from "../element/ButtonSlideUp";

class ModalEditBestSeller extends Component {

    constructor(props) {
        super(props);

        this.state = {
            best_seller: [],
            products: [],
            query: "",
            searched: []
        };

        this.onClickBtnOK = this.onClickBtnOK.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.onSave = this.onSave.bind(this);
    }

    componentWillReceiveProps(props) {
        if (!this.props.show && props.show) {
            this.setState({
                best_seller: props.bestSeller,
                products: props.products,
                query: "",
                searched: []
            });
        }
    }

    onClickBtnOK() {
        this.props.onClickBtnOK(this.state.best_seller);
    }

    render() {
        return <Modal id="modal-edit-contact"
            show={this.props.show}
            btn_ok="Save"
            btn_cancel="Cancel"
            title={"Best Seller"}
            onClickBtnOK={this.onSave}
            onClickBtnCancel={this.props.onClickBtnCancel}>
            Best Seller Products<br />
            {this.renderTable()}
            <br /><br /><br />
            Add Best Seller Product<br />
            {this.renderSearch()}
            <br />
            {this.renderProducts()}
        </Modal>;
    }

    renderTable() {
        return <table className="table table-bordered">
            <thead>
                <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {this.state.best_seller.map(({ prd_name }, i) => <tr key={i}>
                    <td>{i + 1}</td>
                    <td>{prd_name}</td>
                    <td><i style={{ color: "maroon", fontWeight: "bold", cursor: "pointer" }} onClick={() => this.onRemove(i)}>&times;</i></td>
                </tr>)}
            </tbody>
        </table>;
    }

    renderSearch() {
        const search = [];
        if (this.state.error_msg) search.push(<div key="0" style={{ color: "red" }}>{this.state.error_msg}</div>);
        search.push(<div className="mebs-container" key="1">
            <input placeholder="Enter product name (min. 3 char)" value={this.state.query} onChange={(e) => this.setState({ query: e.target.value })} />
            <Button className="button-sm" onClick={this.onSearch}>Search</Button>
        </div>);
        return search;
    }

    onSearch() {
        if (this.state.query.trim().length < 3) {
            this.setState({
                error_msg: "Please enter at least 3 charactes",
                searched: []
            });
        } else {
            let lc_query = this.state.query.trim().toLowerCase();
            let prdcode_groups = this.state.best_seller.map(({ prdcode_group }) => prdcode_group);
            this.setState({
                error_msg: "",
                searched: this.state.products.filter(({ prdcode_group, prd_name }) => {
                    return prd_name.toLowerCase().match(lc_query) && prdcode_groups.indexOf(prdcode_group) === -1;
                })
            });
        }
    }

    onAdd(index) {
        let best_seller = this.state.best_seller;
        best_seller.push(this.state.searched[index]);

        let searched = this.state.searched;
        searched.splice(index, 1);

        this.setState({ best_seller, searched });
    }

    onRemove(index) {
        let best_seller = this.state.best_seller;
        best_seller.splice(index, 1);
        this.setState({
            best_seller
        });
    }

    onSave() {
        this.props.onClickBtnOK(this.state.best_seller.map(({ prdcode_group }) => prdcode_group));
    }

    renderProducts() {
        return this.state.searched.length > 0 && <table className="table table-bordered">
            <thead>
                <tr>
                    <th>No</th>
                    <th>Name</th>
                    <th />
                </tr>
            </thead>
            <tbody>
                {this.state.searched.map(({ prd_name }, i) => {
                    return <tr key={i}>
                        <td>{i + 1}</td>
                        <td>{prd_name}</td>
                        <td><i style={{ color: "green", fontWeight: "bold", cursor: "pointer" }} onClick={() => this.onAdd(i)}>+</i></td>
                    </tr>;
                })}

            </tbody>
        </table>;
    }
}

ModalEditBestSeller.defaultProps = {
    data: {},
    onClickBtnOK: () => { },
    onClickBtnCancel: () => { }
};

export default ModalEditBestSeller;