

import React, { Component } from "react";
import "../sass/element/loader.scss";
import classnames from "classnames";
import { connect } from "react-redux";

class Loader extends Component {

    render() {
        return this.props.showLoader && <div className={classnames("ld-container")}>
            <div className="ld-content">
                <img alt="" src={`${process.env.PUBLIC_URL}/images/spinner.gif`} />
            </div>
        </div>;
    }
}

export default connect(({ loader: { showLoader } }) => ({ showLoader }))(Loader);