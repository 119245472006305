import React, { Component } from "react";
import "../sass/page/contact-info.scss";
import { connect } from "react-redux";
import CartItem from "../component/CartItem";
import Button from "../element/ButtonSlideUp";
import ShipmentApi from "../api/shipment";
import SalesApi from "../api/sales";
import ErrorMsg from "../component/ErrorMsg";
import ValidatorContactInfo from "../validator/ContactInfo";
import Page from "./Page";

class ContactInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cnt_name: "",
            cnt_email: "",
            cnt_address_1: "",
            cnt_address_2: "",
            cnt_city: {},
            cnt_province: {},
            cnt_postal_code: "",
            cnt_phone: "",
            provinces: [],
            cities: [],
            services: [],
            error_msg: "",
            shipment_service: {}
        };

        this.snapPay = this.snapPay.bind(this);
    }

    componentWillMount() {
        document.title = "Bali Asli - Checkout";
        ShipmentApi.getProvinces(this.props.dispatch).then((provinces) => this.setState({ provinces }));
        ShipmentApi.getCities(this.props.dispatch).then((cities) => this.setState({ cities }));
        this.props.dispatch({
            type: "PAGE_VIEW",
            payload: "/checkout"
        });
    }

    renderItems() {
        let items = [];
        for (let prd_id in this.props.cart) {
            items.push(<CartItem editable={false} qty-box={false} data={this.props.cart[prd_id]} key={prd_id} />);
        }
        return items;
    }

    renderCities() {
        return this.state.cities
            .filter(({ province_id }) => province_id === this.state.cnt_province.province_id)
            .map(({ city_id, city_name }, i) => <option key={i} value={city_id}>{city_name}</option>);
    }

    renderProvinces() {
        return this.state.provinces.map(({ province_id, province }, i) => <option key={i} value={province_id}>{province}</option>);
    }

    onChange(field, value) {
        this.setState({ [field]: value }, () => {
            if (field === "cnt_city") {
                this.setState({
                    shipment_service: {
                        shp_service: -1
                    }
                });
                this.fetchCost();
            }
        });
    }

    fetchCost() {
        ShipmentApi.getCosts({
            destination: this.state.cnt_city.city_id,
            weight: this.getWeight()
        }, this.props.dispatch).then((services) => this.setState({ services }));
    }

    renderInputInfo() {
        return <div className="cnti-info-container">
            {this.state.error_msg && <ErrorMsg>* {this.state.error_msg}</ErrorMsg>}
            Full Name <input value={this.state.cnt_name} onChange={({ target: { value } }) => this.onChange("cnt_name", value)} />
            Email <input value={this.state.cnt_email} onChange={({ target: { value } }) => this.onChange("cnt_email", value)} />
            Phone <input value={this.state.cnt_phone} onChange={({ target: { value } }) => this.onChange("cnt_phone", value)} />
            Address <input value={this.state.cnt_address_1} onChange={({ target: { value } }) => this.onChange("cnt_address_1", value)} />
            Building Name (optional)<input value={this.state.cnt_address_2} onChange={({ target: { value } }) => this.onChange("cnt_address_2", value)} />
            Province <select value={this.state.cnt_province.province_id} onChange={({ target: { value } }) => this.onChange("cnt_province", this.state.provinces.find(({ province_id }) => value === province_id) || {})} >
                <option>-- Select a province --</option>
                {this.renderProvinces()}
            </select>
            City <select value={this.state.cnt_city.city_id} onChange={({ target: { value } }) => this.onChange("cnt_city", this.state.cities.find(({ city_id }) => value === city_id) || {})} >
                <option>-- Select a city --</option>
                {this.renderCities()}
            </select>
            Postal Code <input value={this.state.cnt_postal_code} onChange={({ target: { value } }) => this.onChange("cnt_postal_code", value)} />
            Shipment Service <select value={this.state.shipment_service.shp_service} onChange={({ target: { value } }) => this.onChange("shipment_service", this.state.services.find(({ shp_service }) => value === shp_service))}>
                <option value={-1}>-- Select a service --</option>
                {this.renderServices()}
            </select>
        </div>;
    }

    getWeight() {
        let weight = 0;
        for (let prd_id in this.props.cart) {
            let { prd_weight = 1000, qty } = this.props.cart[prd_id];
            weight += prd_weight * qty;
        }

        return weight;
    }

    renderServices() {
        return this.state.services.map(({ shp_service, shp_cost }, i) => <option key={i} value={shp_service}>{shp_service} IDR {shp_cost.toLocaleString()}</option>);
    }

    renderTotalWeight() {
        return <div className="cnti-shipment-weight">Total weight (with packaging) {this.getWeight().toLocaleString()} gr</div>;
    }

    renderShipmentFee() {
        let shp_cost = this.state.shipment_service.shp_cost ? `IDR ${Number(this.state.shipment_service.shp_cost).toLocaleString()}` : "(Please fill in shipping address)";
        return <div className="cnti-shipment-fee">Shipment Fee {shp_cost}</div>;
    }

    renderNotes() {
        return <div className="cnti-notes">
            <div>* Size in cart including packaging</div>
            <div>* Ukuran berat di dalam keranjang sudah termasuk dengan berat kemasan pengiriman</div>
        </div>;
    }

    renderItemSummary() {
        return <div className="cnti-item-summary">
            {this.renderItems()}
            {this.renderTotalWeight()}
            {this.renderShipmentFee()}
            {this.renderTotal()}
            {this.renderNotes()}
        </div>;
    }

    render() {
        return <Page>
            <div className="cnti-container">
                <div className="ba-title" id="cnti-title">Contact Information</div>
                <div className="cnti-flex-container">
                    {this.renderInputInfo()}
                    {this.renderItemSummary()}
                </div>
                <div className="button-group">
                    <Button onClick={() => this.props.history.push("/cart")}><i className="icon-caret-left" />&nbsp;Back to Cart</Button>
                    <Button onClick={() => this.onClickNext()}>Continue Payment&nbsp;<i className="icon-caret-right" /></Button>
                </div>
            </div>
        </Page>;
    }

    onClickNext() {
        ValidatorContactInfo.validateInfo(this.state)
            .then(() => {
                this.setState({ error_msg: "" });
                this.submitSales();

            })
            .catch(({ type, msg }) => {
                this.setState({ [type]: msg });
                window.scrollTo({ top: 0, behavior: "smooth" });
            });
    }

    submitSales() {

        //SUBMIT SALES
        SalesApi.submitSales({
            cart: Object.values(this.props.cart),
            contact: {
                cnt_name: this.state.cnt_name,
                cnt_email: this.state.cnt_email,
                cnt_address_1: this.state.cnt_address_1,
                cnt_address_2: this.state.cnt_address_2,
                cnt_city: this.state.cnt_city,
                cnt_province: this.state.cnt_province,
                cnt_postal_code: this.state.cnt_postal_code,
                cnt_phone: this.state.cnt_phone
            },
            shipment_service: this.state.shipment_service
        }, this.props.dispatch)
            .then((snap_token) => this.snapPay(snap_token))
            .catch((e) => this.setState({ error_msg: e }));
    }

    snapPay(snap_token) {
        window.snap.pay(snap_token, {
            onSuccess: (result) => {
                this.props.dispatch({ type: "PAYMENT_FINISH" });
                this.props.dispatch({
                    type: "RESET_CART"
                });
                this.props.history.replace("/payment/finish");
            },
            onPending: (result) => {
                this.props.dispatch({
                    type: "RESET_CART"
                });
                this.props.history.replace("/payment/pending");
            },
            onError: (result) => {
                this.setState({ error_msg: JSON.stringify(result) });
            }
        });
    }


    renderSubtotal() {
        let subtotal = 0;
        for (let prd_id in this.props.cart) {
            let { qty, prd_price } = this.props.cart[prd_id];
            subtotal += qty * prd_price;
        }
        return <div className="cnti-shipment-fee">Subtotal IDR {subtotal.toLocaleString()}</div>;
    }

    renderTotal() {
        let subtotal = this.state.shipment_service.shp_cost || 0;
        for (let prd_id in this.props.cart) {
            let { qty, prd_price } = this.props.cart[prd_id];
            subtotal += qty * prd_price;
        }
        return <div className="cnti-subtotal">Total IDR {subtotal.toLocaleString()}</div>;
    }
}

export default connect(({ cart: { cart } }) => ({ cart }))(ContactInfo);