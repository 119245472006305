import React, { Component } from "react";
import "../sass/component/footer.scss";
import ContentApi from "../api/content";
import { connect } from "react-redux";
import { FACEBOOK, INSTAGRAM, SCHEDULE_VISIT, FOOTER_ABOUT, FOOTER_OFFICE, FOOTER_CONTACT } from "../config/content";
import ButtonEdit from "../element/ButtonEdit";
import ModalEditContent from "./ModalEditContent";
import ModalEditContact from "./ModalEditContact";
import ModalEditSocmed from "./ModalEditSocmed";
import ButtonSlideUp from "../element/ButtonSlideUp";
import ModalEditVisit from "./ModalEditVisit";
import moment from "moment";
import MailApi from "../api/mail";

class Footer extends Component {

  constructor(props) {
    super(props);
    this.state = {

      show_edit_content: false,
      edit_content_data: {},

      show_edit_contact: false,

      show_edit_socmed: false,
    };

    this.onCloseModalEdit = this.onCloseModalEdit.bind(this);

    this.onClickEditOffice = this.onClickEditOffice.bind(this);
    this.onClickEditAbout = this.onClickEditAbout.bind(this);
    this.onClickEditContact = this.onClickEditContact.bind(this);
    this.onClickEditSocmed = this.onClickEditSocmed.bind(this);
    this.onEditContent = this.onEditContent.bind(this);
    this.onClickEditSchedule = this.onClickEditSchedule.bind(this);
  }

  componentWillMount() {
    let cten_names = [FACEBOOK, INSTAGRAM, FOOTER_ABOUT, FOOTER_OFFICE, FOOTER_CONTACT, SCHEDULE_VISIT];
    ContentApi.getContents(cten_names, this.props.dispatch).then((contents) => {
      this.props.dispatch({
        type: "UPDATE_CONTENTS",
        payload: contents
      });
    });

  }

  onCloseModalEdit() {
    this.setState({
      show_edit_content: false,
      show_edit_contact: false,
      show_edit_socmed: false,
      show_edit_schedule: false
    });
  }

  onClickEditSchedule() {
    this.setState({
      show_edit_schedule: true,
      edit_content_data: {
        title: this.props[SCHEDULE_VISIT].title,
        desc: this.props[SCHEDULE_VISIT].desc,
        email: this.props[SCHEDULE_VISIT].email,

      }
    });
  }

  onClickEditOffice() {
    this.setState({
      show_edit_content: true,
      edit_content_data: {
        title: "Office",
        name: FOOTER_OFFICE,
        value: this.props[FOOTER_OFFICE].value
      }
    });
  }

  onClickEditAbout() {
    this.setState({
      show_edit_content: true,
      edit_content_data: {
        title: "About Us",
        name: FOOTER_ABOUT,
        value: this.props[FOOTER_ABOUT].value
      }
    });
  }

  onClickEditContact() {
    this.setState({
      show_edit_contact: true
    });
  }

  onClickEditSocmed() {
    this.setState({
      show_edit_socmed: true
    });
  }

  render() {
    return <div id="footer">
      <footer>
        <div className="footer-container">
          <div className="footer-about-us"><h3>BALI ASLI</h3><br />
            {this.props[FOOTER_ABOUT].value}
            <br /><br /><ButtonEdit
              className="button-sm"
              onClick={this.onClickEditAbout} />
            <br /><br />
            <h3>OFFICE</h3><br />
            {this.props[FOOTER_OFFICE].value}
            <br /><br /><ButtonEdit
              className="button-sm"
              onClick={this.onClickEditOffice} />
          </div>
          <div className="footer-contact-us"><h3>CONTACT US</h3><br />
            Phone {this.props[FOOTER_CONTACT].phone}<br />
            Whatsapp <a href={`https://web.whatsapp.com/send?phone=${this.props[FOOTER_CONTACT].whatsapp}`} rel="noopener noreferrer" target="_blank">{this.props[FOOTER_CONTACT].whatsapp}</a><br />
            Email <a href={`mailto:${this.props[FOOTER_CONTACT].email}`}>{this.props[FOOTER_CONTACT].email}</a>
            <br /><br /><ButtonEdit
              className="button-sm"
              onClick={this.onClickEditContact} />
          </div>
          <div className="footer-address"><h3>{this.props[SCHEDULE_VISIT].title}</h3><br />
            {this.props[SCHEDULE_VISIT].desc}
            {this.renderNPE()}
            <br /><br /><ButtonEdit
              className="button-sm"
              onClick={this.onClickEditSchedule} />
          </div>
          <div className="footer-follow-us"><h3>FOLLOW US</h3><br />
            <a target="_blank" rel="noopener noreferrer" href={this.props[FACEBOOK].url}><i className="icon-facebook" style={{ marginRight: "1rem" }} /></a>
            <a target="_blank" rel="noopener noreferrer" href={this.props[INSTAGRAM].url}><i className="icon-instagram" /></a>
            <br /><br /><ButtonEdit className="button-sm" onClick={this.onClickEditSocmed} />
          </div>
        </div>
        <div style={{ textAlign: "left", marginLeft: "2rem", letterSpacing: "1px" }}>{`COPYRIGHT ${moment().format("YYYY")}`} &copy; BALI ASLI</div>
      </footer>
      <ModalEditSocmed onClickBtnCancel={this.onCloseModalEdit} onClickBtnOK={this.onEditContent} facebook={this.props[FACEBOOK]} instagram={this.props[INSTAGRAM]} show={this.state.show_edit_socmed} />
      <ModalEditContent onClickBtnCancel={this.onCloseModalEdit} onClickBtnOK={this.onEditContent} data={this.state.edit_content_data} show={this.state.show_edit_content} />
      <ModalEditContact onClickBtnCancel={this.onCloseModalEdit} onClickBtnOK={this.onEditContent} footer-contact={this.props[FOOTER_CONTACT]} show={this.state.show_edit_contact} />
      <ModalEditVisit onClickBtnCancel={this.onCloseModalEdit} onClickBtnOK={this.onEditContent} title={this.props[SCHEDULE_VISIT].title} desc={this.props[SCHEDULE_VISIT].desc} email={this.props[SCHEDULE_VISIT].email} show={this.state.show_edit_schedule} />
    </div>;
  }

  onEditContent(cten_name, cten_value) {

    this.props.dispatch({
      type: "SHOW_CONFIRM",
      payload: "Are you sure you want to save changes ?",
      callback: () => ContentApi.update({ cten_name, cten_value }, this.props.dispatch)
        .then(() => {
          this.props.dispatch({ type: "SHOW_INFO", payload: "Data sucessfully updated" });
          this.props.dispatch({ type: "UPDATE_CONTENTS", payload: { [cten_name]: cten_value } });
          this.onCloseModalEdit();
        })
    });
  }

  renderNPE() {
    return <div className="footer-sv">
      <div><input placeholder="Enter your e-mail address" value={this.state.sv_email} onChange={({ target: { value: sv_email } }) => this.setState({ sv_email })} /></div>
      <div><textarea rows="3" placeholder="Enter your message here" value={this.state.sv_message} onChange={({ target: { value: sv_message } }) => this.setState({ sv_message })} /></div>
      <ButtonSlideUp className="button-sm" onClick={() => this.sendEmail()}>Submit</ButtonSlideUp>
    </div>;
  }
  sendEmail() {
    let data = {
      type: "schedule-visit",
      email: this.state.sv_email,
      message: this.state.sv_message
    };
    MailApi.send(data, this.props.dispatch)
      .then(() => {
        this.props.dispatch({ type: "EMAIL_VISIT" });
        this.props.dispatch({ type: "SHOW_INFO", payload: "Message sucessfully sent" });
        this.setState({
          sv_email: "",
          sv_message: ""
        });
      });
  }
}

export default connect(({ content }) => ({
  [FOOTER_ABOUT]: content[FOOTER_ABOUT],
  [FOOTER_OFFICE]: content[FOOTER_OFFICE],
  [FOOTER_CONTACT]: content[FOOTER_CONTACT],
  [FACEBOOK]: content[FACEBOOK],
  [INSTAGRAM]: content[INSTAGRAM],
  [SCHEDULE_VISIT]: content[SCHEDULE_VISIT]
}))(Footer);