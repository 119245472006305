import React, { Component } from "react";
import "../sass/component/modal-detail-item.scss";
import Modal from "../element/Modal";

class ModalEditContent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: "initial-title",
            name: "initial-name",
            value: "initial-value"
        };

        this.onClickBtnOK = this.onClickBtnOK.bind(this);
    }

    componentWillReceiveProps(props) {
        if (!this.props.show && props.show) {
            this.setState(props.data);
        }
    }

    onClickBtnOK() {
        this.props.onClickBtnOK(this.state.name, {value: this.state.value});
    }

    render() {
        return <Modal id="modal-edit-content"
            show={this.props.show}
            btn_ok="Save"
            btn_cancel="Cancel"
            title={"Edit " + this.state.title}
            onClickBtnOK={this.onClickBtnOK}
            onClickBtnCancel={this.props.onClickBtnCancel}>
            <textarea rows="5" onChange={({target:{value}}) => this.setState({value})} value={this.state.value}>{this.state.value}</textarea>
        </Modal>;
    }
}

ModalEditContent.defaultProps = {
    data: {},
    onClickBtnOK: () => {},
    onClickBtnCancel: () => {}
};

export default ModalEditContent;