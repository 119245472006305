import request from "superagent";
import Helper from "./helper";

export default {
    send: (body, dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        request
            .post(`${process.env.REACT_APP_API_URL}/mail`)
            .set("Accept", "application/json")
            .send(body)
            .end((err, res) => {
                Helper.onResponse(dispatch);
                if (res && res.body.status=== "success") {
                    resolve(res.body.payload);
                } else {
                    Helper.onError(err, res, dispatch);
                }
            });
    })
};