export default function reducer(
  state = {
    showConfirm: false,
    msgConfirm: "",
    cbConfirm: false,
  },
  { type, payload, callback }
) {
  console.log("TYPE", type);
  switch (type) {
    case "SHOW_CONFIRM": {
      return {
        ...state,
        showConfirm: true,
        msgConfirm: payload,
        cbConfirm: callback,
      };
    }
    case "HIDE_CONFIRM": {
      return { ...state, showConfirm: false, cbConfirm: false };
    }
    default:
      return state;
  }
}
