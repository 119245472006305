import request from "superagent";
import Helper from "./helper";

export default {
    getNews: (dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        request
            .get(`${process.env.REACT_APP_API_URL}/news`)
            .set("Accept", "application/json")
            .end((err, res) => {
                Helper.onResponse(dispatch);
                if (res && res.body.status=== "success") {
                    resolve(res.body.payload);
                } else {
                    Helper.onError(err, res, dispatch);
                }
            });
    }),
    update: (news, file, dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        let req = request
            .put(`${process.env.REACT_APP_API_URL}/admin/news`)
            .set("Accept", "application/json")
            .set("Authorization", "Bearer " + localStorage.getItem("jwt"));

        for (let fieldname in news) {
            req = req.field(fieldname, news[fieldname]);
        }

        if (file) {
            req = req.attach("news", file);
        }

        req.end((err, res) => {
            Helper.onResponse(dispatch);
            if (res && res.body.status=== "success") {
                resolve(res.body.payload);
            } else {
                Helper.onError(err, res, dispatch);
            }
        });
    }),
    add: (news, file, dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        let req = request
            .post(`${process.env.REACT_APP_API_URL}/admin/news`)
            .set("Accept", "application/json")
            .set("Authorization", "Bearer " + localStorage.getItem("jwt"));

        for (let fieldname in news) {
            req = req.field(fieldname, news[fieldname]);
        }

        if (file) {
            req = req.attach("news", file);
        }

        req.end((err, res) => {
            Helper.onResponse(dispatch);
            if (res && res.body.status=== "success") {
                resolve(res.body.payload);
            } else {
                Helper.onError(err, res, dispatch);
            }
        });
    }),
    delete: (news_id, dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        request
            .delete(`${process.env.REACT_APP_API_URL}/admin/news/${news_id}`)
            .set("Accept", "application/json")
            .set("Authorization", "Bearer " + localStorage.getItem("jwt"))
            .end((err, res) => {
                Helper.onResponse(dispatch);
                if (res && res.body.status=== "success") {
                    resolve(res.body.payload);
                } else {
                    Helper.onError(err, res, dispatch);
                }
            });
    })
};