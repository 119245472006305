import request from "superagent";
import Helper from "./helper";

export default {
    getProductsAdmin: (dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        request
            .get(`${process.env.REACT_APP_API_URL}/admin/product`)
            .set("Accept", "application/json")
            .set("Authorization", "Bearer " + localStorage.getItem("jwt"))
            .end((err, res) => {
                Helper.onResponse(dispatch);
                if (res && res.body.status=== "success") {
                    resolve(res.body.payload);
                } else {
                    Helper.onError(err, res, dispatch);
                }
            });
    }),
    getProducts: (dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        request
            .get(`${process.env.REACT_APP_API_URL}/product`)
            .set("Accept", "application/json")
            .end((err, res) => {
                Helper.onResponse(dispatch);
                if (res && res.body.status=== "success") {
                    resolve(res.body.payload);
                } else {
                    Helper.onError(err, res, dispatch);
                }
            });
    }),
    create: (data, file, dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        let req = request
            .post(`${process.env.REACT_APP_API_URL}/admin/product`)
            .set("Accept", "application/json")
            .set("Authorization", "Bearer " + localStorage.getItem("jwt"));

        for (let fieldname in data) {
            req = req.field(fieldname, data[fieldname]);
        }

        if (file) {
            req = req.attach("prd_img", file);
        }

        req.end((err, res) => {
            Helper.onResponse(dispatch);
            if (res && res.body.status=== "success") {
                resolve(res.body.payload);
            } else {
                Helper.onError(err, res, dispatch);
            }
        });
    }),
    update: (data, file, dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        let req = request
            .put(`${process.env.REACT_APP_API_URL}/admin/product`)
            .set("Accept", "application/json")
            .set("Authorization", "Bearer " + localStorage.getItem("jwt"));

        for (let fieldname in data) {
            req = req.field(fieldname, data[fieldname] || "");
        }

        if (file) {
            req = req.attach("prd_img", file);
        }

        req.end((err, res) => {
            Helper.onResponse(dispatch);
            if (res && res.body.status=== "success") {
                resolve(res.body.payload);
            } else {
                Helper.onError(err, res, dispatch);
            }
        });
    }),
    multiple_update: (data, files, dispatch) => new Promise((resolve, reject) => {

        Helper.onRequest(dispatch);
        let req = request
            .put(`${process.env.REACT_APP_API_URL}/admin/product-bulk`)
            .set("Accept", "application/json")
            .set("Authorization", "Bearer " + localStorage.getItem("jwt"));

        
        req = req.field("products", JSON.stringify(data || []));
        
        for(let name in files){
            req = req.attach(`${name}`, files[name]);
        }

        req.end((err, res) => {
            Helper.onResponse(dispatch);
            if (res && res.body.status=== "success") {
                resolve(res.body.payload);
            } else {
                Helper.onError(err, res, dispatch);
            }
        });
    }),
    getBestSeller: (dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        request
            .get(`${process.env.REACT_APP_API_URL}/product/best-seller`)
            .set("Accept", "application/json")
            .end((err, res) => {
                Helper.onResponse(dispatch);
                if (res && res.body.status=== "success") {
                    resolve(res.body.payload);
                } else {
                    Helper.onError(err, res, dispatch);
                }
            });
    }),
    upsertBestSeller: (data, dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        request
            .put(`${process.env.REACT_APP_API_URL}/admin/product/best-seller`)
            .send(data)
            .set("Accept", "application/json")
            .set("Authorization", "Bearer " + localStorage.getItem("jwt"))
            .end((err, res) => {
                Helper.onResponse(dispatch);
                if (res && res.body.status=== "success") {
                    resolve(res.body.payload);
                } else {
                    Helper.onError(err, res, dispatch);
                }
            });
    })
};