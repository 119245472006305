import React, { Component } from "react";
import "../sass/component/modal-detail-item.scss";
import Modal from "../element/Modal";

class ModalProductCategory extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: props.title,
            desc: props.desc,
            url: props.url,
            button_text: props.button_text,
        };

        this.onClickBtnOK = this.onClickBtnOK.bind(this);
    }

    componentWillReceiveProps(props) {
        if (!this.props.show && props.show) {
            this.setState(props.data);

            this.edited_bg = false;
            this.file_bg = false;

            if (this.refInputBG) {
                this.refInputBG.value = "";
            }
        }
    }

    onClickBtnOK() {
        this.props.onClickBtnOK(this.state.title, this.state.desc, this.state.url, this.state.button_text, this.file_bg);
    }

    render() {
        return <Modal id="modal-edit-visit"
            show={this.props.show}
            btn_ok="Save"
            btn_cancel="Cancel"
            title={"Edit Category"}
            onClickBtnOK={this.onClickBtnOK}
            onClickBtnCancel={this.props.onClickBtnCancel}>
            <div style={{ textAlign: "center" }}>
                <label htmlFor={"edit-pc-bg"}>
                    <img style={{ width: "40rem" }} src={this.edited_bg ? this.state.img : `${process.env.REACT_APP_API_URL}${this.props.data.img}`} alt="Bali Asli" />
                </label>
                <input type="file" ref={(ref) => this.refInputBG = ref} onChange={(e) => this.onChangeLogo(e)} id={"edit-pc-bg"} />
            </div>
            <br />Title :<br />
            <textarea onChange={({ target: { value: title } }) => this.setState({ title })} value={this.state.title} /><br /><br />
            Description :<br />
            <textarea onChange={({ target: { value: desc } }) => this.setState({ desc })} value={this.state.desc} /><br /><br />
            Link :<br />
            <textarea onChange={({ target: { value: url } }) => this.setState({ url })} value={this.state.url} /><br /><br />
            Button Text:<br />
            <textarea onChange={({ target: { value: button_text } }) => this.setState({ button_text })} value={this.state.button_text} /><br /><br />
        </Modal>;
    }

    onChangeLogo(e) {

        this.file_bg = e.target.files[0];
        this.edited_bg = true;

        this.setState({ img: URL.createObjectURL(this.file_bg) });

    }
}

ModalProductCategory.defaultProps = {
    data: {},
    onClickBtnOK: () => { },
    onClickBtnCancel: () => { }
};

export default ModalProductCategory;