import React, { Component } from "react";
import "../sass/component/modal-detail-item.scss";
import Modal from "../element/Modal";
import { FACEBOOK, INSTAGRAM } from "../config/content";

class ModalEditSocmed extends Component {

    constructor(props) {
        super(props);

        this.state = {
            facebook:{},
            instagram: {}
        };

        this.onClickBtnOK = this.onClickBtnOK.bind(this);
    }

    componentWillReceiveProps(props) {
        if (!this.props.show && props.show) {
            this.setState({facebook: props.facebook, instagram: props.instagram});
        }
    }

    onClickBtnOK() {
        this.props.onClickBtnOK(FACEBOOK, this.state.facebook);
        this.props.onClickBtnOK(INSTAGRAM, this.state.instagram);
    }

    render() {
        return <Modal id="modal-edit-socmed"
            show={this.props.show}
            btn_ok="Save"
            btn_cancel="Cancel"
            title={"Edit Social Media Link"}
            onClickBtnOK={this.onClickBtnOK}
            onClickBtnCancel={this.props.onClickBtnCancel}>
            Facebook :<br/>
            <textarea onChange={({target:{value:url}}) => this.setState({facebook:{url}})} value={this.state.facebook.url}/><br/><br/>
            Instagram :<br/>
            <textarea onChange={({target:{value:url}}) => this.setState({instagram:{...this.state.instagram, url}})} value={this.state.instagram.url}/><br/><br/>
        </Modal>;
    }
}

ModalEditSocmed.defaultProps = {
    data: {},
    onClickBtnOK: () => {},
    onClickBtnCancel: () => {}
};

export default ModalEditSocmed;