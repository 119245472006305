import request from "superagent";
import Helper from "./helper";
import {
    ABOUT_US
} from "../config/content";

const getContents = (cten_names, dispatch, first_cten_value) => new Promise((resolve, reject) => {
    Helper.onRequest(dispatch);
    request
        .get(`${process.env.REACT_APP_API_URL}/content?cten_name[]=${cten_names.join("&cten_name[]=")}`)
        .set("Accept", "application/json")
        .end((err, res) => {
            Helper.onResponse(dispatch);
            if (res && res.body.status=== "success") {
                first_cten_value && res.body.payload.length > 0 ?
                    resolve(Helper.JSONparse(res.body.payload[0].cten_value)) :
                    resolve(res.body.payload.reduce((obj, row) => {
                        obj[row.cten_name] = Helper.JSONparse(row.cten_value);
                        return obj;
                    }, {}));
            } else {
                Helper.onError(err, res, dispatch);
            }
        });
});

export default {

    update: (data, dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        request
            .put(`${process.env.REACT_APP_API_URL}/admin/content`)
            .set("Accept", "application/json")
            .set("Authorization", "Bearer " + localStorage.getItem("jwt"))
            .send(data)
            .end((err, res) => {
                Helper.onResponse(dispatch);
                if (res && res.body.status=== "success") {
                    resolve(res.body.payload);
                } else {
                    Helper.onError(err, res, dispatch);
                }
            });
    }),
    updateAbout: (title, desc, file, dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        let req = request
            .put(`${process.env.REACT_APP_API_URL}/admin/content-file/about`)
            .set("Accept", "application/json")
            .set("Authorization", "Bearer " + localStorage.getItem("jwt"))
            .field("title", title)
            .field("desc", desc);

        if (file) {
            req = req.attach("about", file);
        }

        req.end((err, res) => {
            Helper.onResponse(dispatch);
            if (res && res.body.status=== "success") {
                resolve(res.body.payload);
            } else {
                Helper.onError(err, res, dispatch);
            }
        });
    }),
    updateWithFiles: ({
        cten_name,
        cten_value,
        files = [], file_bg
    }, dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        let req = request
            .put(`${process.env.REACT_APP_API_URL}/admin/content-file`)
            .set("Accept", "application/json")
            .set("Authorization", "Bearer " + localStorage.getItem("jwt"))
            .field("cten_name", cten_name)
            .field("cten_value", JSON.stringify(cten_value));

        for (let i = 0; i < files.length; ++i) {
            req = req.attach(`${cten_name}-${i}`, files[i]);
        }

        if(file_bg){
            req = req.attach("background", file_bg);
        }

        req.end((err, res) => {
            Helper.onResponse(dispatch);
            if (res && res.body.status=== "success") {
                resolve(res.body.payload);
            } else {
                Helper.onError(err, res, dispatch);
            }
        });
    }),
    getAbout: (dispatch) => getContents([ABOUT_US], dispatch, true),
    getContents
};