import React, { Component } from "react";
import Modal from "../element/Modal";
import { connect } from "react-redux";

class ModalConfirm extends Component {

    render() {
        return <Modal id="modal-confirm"
            show={this.props.showConfirm}
            btn_ok={"OK"}
            btn_cancel={"Cancel"}
            onClickBtnOK={() => this.onConfirm()}
            onClickBtnCancel={() => this.onClose()}>

            {this.props.msgConfirm}

        </Modal>;
    }

    onConfirm(){
        this.props.cbConfirm && this.props.cbConfirm();
        this.onClose();
    }

    onClose() {
        this.props.dispatch({
            type: "HIDE_CONFIRM"
        });
    }
}

export default connect(({ confirm: { showConfirm, msgConfirm, cbConfirm } }) => ({ showConfirm, msgConfirm, cbConfirm }))(ModalConfirm);