export default function reducer(
  state = {
    showInfo: false,
    msgInfo: "",
    cbInfo: false,
  },
  { type, payload, callback }
) {
  switch (type) {
    case "SHOW_INFO": {
      return { ...state, showInfo: true, msgInfo: payload, cbInfo: callback };
    }
    case "HIDE_INFO": {
      return { ...state, showInfo: false, cbInfo: false };
    }
    default:
      return state;
  }
}
