import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {Provider} from "react-redux";
import store from "./store/store";
import PaymentFinish from "./page/PaymentFinish";
import PaymentError from "./page/PaymentError";
import PaymentPending from "./page/PaymentPending";
import Home from "./page/Home";
import Shop from "./page/Shop";
import "./sass/env.scss";
import "./sass/element/input.scss";
import "./sass/font-puritan.scss";
import "./sass/base.scss";
import "./sass/override.scss";
import About from "./page/About";
import News from "./page/News";
import Cart from "./page/Cart";
import ContactInfo from "./page/ContactInfo";
import Loader from "./element/Loader";
import LoginAdmin from "./page/LoginAdmin";
import ModalInfo from "./component/ModalInfo";
import ModalConfirm from "./component/ModalConfirm";
import Sales from "./page/Sales";
import TermsConditions from "./page/TermsConditions";

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <section>
            <Switch>
              <Route path="/admin/login" component={LoginAdmin} />
              <Route path="/checkout" component={ContactInfo} />
              <Route path="/cart" component={Cart} />
              <Route path="/news/:news_id" component={News} />
              <Route path="/news" component={News} />
              <Route path="/shop/:prdcat_id" component={Shop} />
              <Route path="/shop" component={Shop} />
              <Route path="/about" component={About} />
              <Route path="/payment/error" component={PaymentError} />
              <Route path="/payment/finish" component={PaymentFinish} />
              <Route path="/payment/pending" component={PaymentPending} />
              <Route path="/sales" component={Sales} />
              <Route path="/terms-conditions" component={TermsConditions} />
              <Route path="/" component={Home} />
            </Switch>
          </section>
        </Router>
        <Loader/>
        <ModalInfo/>
        <ModalConfirm/>
      </Provider>
    );
  }
}

export default App;
