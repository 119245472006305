import React, { Component } from "react";
import "../sass/page/cart.scss";
import { connect } from "react-redux";
import QtyBox from "../component/QtyBox";

class CartItem extends Component {

    onChange(qty) {

        this.props.dispatch({
            type: "MODIFY_CART",
            payload: {
                qty,
                product: this.props.data
            }
        });
    }

    render() {
        let { prd_img, prd_name, qty, prd_price, prd_id, prd_size } = this.props.data;
        return <div className="mcart-item-container">
            <div className="mcart-item prd_img" style={{ backgroundImage: `url(${process.env.REACT_APP_API_URL}${prd_img})` }} />
            <div className="mcart-item prd_name">{prd_name} ({prd_size})</div>
            <div className="mcart-item prd_qty">{this.props["qty-box"] ? <QtyBox qty={qty} onChange={(new_qty) => this.onChange(new_qty)} /> : qty.toLocaleString()}</div>
            <div className="mcart-item prd_price">IDR {(qty * prd_price).toLocaleString()}</div>
            {this.props.editable && <div className="mcart-item prd_delete" onClick={() => this.deleteItem(prd_id)}>×</div>}
        </div>;
    }

    deleteItem(prd_id) {
        this.props.dispatch({
            type: "DELETE_FROM_CART",
            payload: prd_id
        });
    }
}
CartItem.defaultProps = {
    "data": {},
    "qty-box": true,
    "editable": true
};
export default connect(({ }) => ({}))(CartItem);