export default {
  areaCode: (str) => String(str).match(/^(\+)?[[0-9]*$/),
  email: (str) =>
    String(str).match(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    ),
  number: (str) => String(str).match(/^[0-9]+$/),
  ccValid: (str) => String(str).match(/^[0-9]{2}\/[0-9]{2}$/),
  ccExpiry: (str) => String(str).match(/^[0-9,/]*$/),
};
