import React, { Component } from "react";
import "../sass/page/about.scss";
import Separator from "../element/Separator";
import { withRouter } from "react-router";
import Page from "./Page";
import ButtonSlideUp from "../element/ButtonSlideUp";

class TermsConditions extends Component {

    render() {
        return <Page>
            <div style={{ textAlign: "center", fontSize: "2.5rem" }}>
                <Separator />
                Exchange and No Refund Policy
                <Separator height="2rem" />
                <span style={{ fontSize: "2rem" }}>We are not required to provide any refund for any transaction.<br/>If an item is broken you can request an exchange within 2 days after the item has been received by sending us<br/>an email to <a href="mailto:info@bali-asli.com">info@bali-asli.com</a> and send back the broken item to our office at
Jl.Gn. Salak No.88E, Denpasar Barat, Bali.
    <br/>We will immediately send a new item with the same kind.</span>
                <Separator height="2rem" />
                <ButtonSlideUp onClick={() => this.props.history.replace("/")} className="button-sm">Back to home</ButtonSlideUp>
                <Separator height="13rem"/>
                <br/>
            </div>
        </Page>;
    }
}

export default withRouter(TermsConditions);