import {
  ABOUT_US,
  FACEBOOK,
  FOOTER_CONTACT,
  FOOTER_ABOUT,
  FOOTER_OFFICE,
  SLIDER,
  AVAILABLE_AT,
  HOME_JAM,
  HOME_SOAP,
  INSTAGRAM,
  SCHEDULE_VISIT,
  WHOLESALE_ORDER,
} from "../config/content";

export default function reducer(
  state = {
    [SLIDER]: {},
    [ABOUT_US]: {},
    [AVAILABLE_AT]: {},
    [FACEBOOK]: {},
    [FOOTER_ABOUT]: {},
    [FOOTER_CONTACT]: {},
    [FOOTER_OFFICE]: {},
    [HOME_JAM]: {},
    [HOME_SOAP]: {},
    [INSTAGRAM]: {},
    [SCHEDULE_VISIT]: {},
    [WHOLESALE_ORDER]: {},
    PRODUCT_CATEGORIES: [],
  },
  { type, payload }
) {
  switch (type) {
    case "UPDATE_PRODUCT_CATEGORIES": {
      return {
        ...state,
        PRODUCT_CATEGORIES: payload,
      };
    }
    case "UPDATE_CONTENTS": {
      return {
        ...state,
        ...payload,
      };
    }
    default:
      return state;
  }
}
