import React, { Component } from "react";
import "../sass/page/shop.scss";
import ShopItem from "../component/ShopItem";
import ModalDetailItem from "../component/ModalDetailItem";
import ProductApi from "../api/product";
import ProductCategoryApi from "../api/product_category";
import Page from "./Page";
import ModalEditItem from "../component/ModalEditItem";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

class Shop extends Component {
    constructor(props) {
        super(props);

        this.all_products = {
            prdcat_id: 0,
            prdcat_parent_id: -1,
            prdcat_name: "All Products"
        };

        this.new_product = {
            prd_name: "",
            prd_desc: "",
            prd_ingr: "",
            prdcat_id: 1,
            prd_variants: [{
                prd_variant: "",
                prd_details: [{
                    prd_weight: "",
                    prd_size: "",
                    prd_price: "",
                    prd_stock: 1,
                    status: 1
                }]
            }],
            prd_stock: 1,
            status: 1
        };

        this.state = {

            data: [],
            filter: [],

            initial_data: [],
            initial_filter: [],

            active_prdcat_id: Number(props.match.params.prdcat_id) || 0,
            selected_item: {
                price_min: 0,
                price_max: 0,
                prd_variants: [{
                    prd_details: [{}]
                }]
            },

            modal_detail_item: false,
            show_items: true,
        };

        this.hideModal = this.hideModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.updateFilter = this.updateFilter.bind(this);
        this.onEdit = this.onEdit.bind(this);
        this.onAdd = this.onAdd.bind(this);

        this.map = {};

    }


    componentWillReceiveProps(props) {
        if (props.query && props.query !== this.props.query) {
            this.onSearchProduct(props.query);
        } else {
            this.onClickFilter(Number(props.match.params.prdcat_id) || 0);
        }
    }

    componentWillUnmount() {
        this.props.dispatch({
            type: "RESET_QUERY"
        });
    }

    onSearchProduct(query) {
        let lc_query = query.toLowerCase();
        let data = this.state.initial_data.filter(({ prd_name }) => {
            console.log(lc_query, prd_name, lc_query.match(prd_name.toLowerCase()));
            return prd_name.toLowerCase().match(lc_query);
        });
        this.setState({
            data
        });
    }

    mapParentCategory(product_category, data) {

        for (let row of data) {
            let prdcat_id = row.prdcat_id;
            row.categories = [prdcat_id];
            do {
                prdcat_id = this.map[prdcat_id];
                row.categories.push(prdcat_id);
            } while (prdcat_id !== 0);
        }

        return data;
    }

    componentWillMount() {
        document.title = "Bali Asli - Shop";
        this.props.dispatch({
            type: "PAGE_VIEW",
            payload: "/shop"
        });
        ProductCategoryApi.getProductCategories(this.props.dispatch).then((initial_filter) => {
            this.map = initial_filter.reduce((obj, { prdcat_id, prdcat_parent_id }) => {
                obj[prdcat_id] = prdcat_parent_id;
                return obj;
            }, {});
            this.props.dispatch({
                type: "UPDATE_PRODUCT_CATEGORIES",
                payload: initial_filter
            });
            let getProducts = this.props.isSignedIn ? ProductApi.getProductsAdmin : ProductApi.getProducts;
            getProducts(this.props.dispatch).then((data) => {

                let categorized_data = this.mapParentCategory(initial_filter, data).filter(({ status }) => this.props.isSignedIn || status === 1);
                this.setState({
                    initial_filter,
                    initial_data: categorized_data
                }, () => {
                    this.onClickFilter(this.state.active_prdcat_id);
                });
            }).catch(console.log);
        }).catch(console.log);
    }
    updateFilter() {
        let { initial_filter } = this.state;
        let id = this.state.active_prdcat_id;
        let show_ids = [];
        while (id) {
            show_ids.unshift(id);
            id = this.map[id];
        }
        let filter = initial_filter.filter(({ prdcat_id, prdcat_parent_id }) => {
            return (prdcat_parent_id === this.map[this.state.active_prdcat_id] || //siblings
                prdcat_parent_id === this.state.active_prdcat_id || //children
                show_ids.indexOf(prdcat_id) !== -1 || //parents
                prdcat_parent_id === 0);
        });
        //check filter
        if (filter.length > 1) {
            this.setState({
                filter: [this.all_products, ...filter]
            });
        }
    }

    onClickFilter(active_prdcat_id) {
        let data = this.state.initial_data.filter(({ categories }) => {
            return categories.indexOf(active_prdcat_id) !== -1;
        });
        this.setState({ active_prdcat_id, show_items: false },
            () => {
                this.updateFilter();
                setTimeout(() => {
                    this.setState({ data }, () => setTimeout(() => this.setState({ show_items: true, data }), 100));
                }, 100);
            }
        );

        this.resetQuery();
    }

    resetQuery() {
        this.props.dispatch({
            type: "RESET_QUERY"
        });
    }

    renderFilters() {
        let current_prdcat_parent_id = 0;
        return this.state.filter.map(({ prdcat_id, prdcat_name, prdcat_parent_id }, i) => {
            let active = prdcat_id === this.state.active_prdcat_id ? "active" : "";
            let li = <li key={i} onClick={() => this.props.history.push(`/shop/${prdcat_id}`)} className={`filter ${active}`}>{prdcat_name}</li>;
            let br = current_prdcat_parent_id !== prdcat_parent_id && prdcat_parent_id !== -1 && prdcat_parent_id !== 0 ? <br key={`br-${i}`} /> : null;
            current_prdcat_parent_id = prdcat_parent_id;
            return [br, li];
        });
    }

    renderProducts() {
        let show = this.state.show_items ? "si-show" : "";
        let category;
        const products = [];
        this.state.data.forEach((row, i) => {
            let { prd_id, prd_img, price_min, price_max, prd_name, prd_stock, status, prdcat_parent_id } = row;
            category = category || prdcat_parent_id;
            let prd_price = price_min === price_max ? price_min.toLocaleString() : `${price_min.toLocaleString()} - ${price_max.toLocaleString()}`;

            products.push(<ShopItem key={prd_id}
                onClick={() => this.setState({ selected_item: row, selected_index: i }, this.showModal)}
                onEdit={() => this.setState({ selected_item: row, selected_index: i }, this.showEdit)}
                className={`${show}`}
                prd_stock={prd_stock}
                status={status}
                img={prd_img}
                title={prd_name}
                show={show}
                price={prd_price.toLocaleString()} />);
            if (category !== prdcat_parent_id) {
                products.push(<div key={i} className="shop-item-hr" />);
                category = prdcat_parent_id;
            }
        });
        return products;
    }

    renderQueryInfo() {
        let info = false;
        if (this.props.query) {
            if (this.state.data.length > 0) {
                info = <div style={{ marginBottom: "4rem" }}>{`Showing results for "${this.props.query}"`}</div>;
            } else {
                info = <div style={{ marginBottom: "4rem" }}>{`No product matches "${this.props.query}"`}</div>;
            }
        }
        return info;
    }

    renderAddProduct() {
        return this.props.isSignedIn && <ShopItem
            onClick={() => { }}
            onEdit={() => this.setState({ selected_item: JSON.parse(JSON.stringify(this.new_product)), selected_index: this.state.data.length }, this.showEdit)}
            className="si-show"
            prd_stock={1}
            status={1}
            img="/assets/img/add.svg"
            title="Add New Product"
            price="" />;
    }

    render() {
        return <Page><section className="gallery-section-two">

            <div className="mixitup-gallery">

                <div className="filters">
                    <ul className="filter-tabs filter-btns">
                        {this.renderFilters()}
                    </ul>
                </div>

                {this.renderQueryInfo()}

                <div className="filter-list row clearfix">
                    {this.renderProducts()}
                    {this.renderAddProduct()}
                </div>

            </div>
        </section>
            <ModalEditItem data={this.state.selected_item} onClickBtnOK={this.state.selected_index === this.state.data.length ? this.onAdd : this.onEdit} onClickBtnCancel={this.hideModal} show={this.state.modal_edit_item} />
            <ModalDetailItem data={this.state.selected_item} onClickBtnOK={this.hideModal} onClickBtnCancel={this.hideModal} show={this.state.modal_detail_item} />
        </Page>;
    }

    onAdd(data, file) {
        this.props.dispatch({
            type: "SHOW_CONFIRM",
            payload: "Are you sure you want to add new product ?",
            callback: () => ProductApi.multiple_update(data, file, this.props.dispatch).then(() => this.props.dispatch({ type: "SHOW_INFO", payload: "Data sucessfully updated", callback: () => window.location.reload() }))
        });
    }

    onEdit(data, file) {

        this.props.dispatch({
            type: "SHOW_CONFIRM",
            payload: "Are you sure you want to save changes ?",
            callback: () => ProductApi.multiple_update(data, file, this.props.dispatch).then(() => {
                this.props.dispatch({
                    type: "SHOW_INFO",
                    payload: "Data sucessfully updated",
                    callback: () => window.location.reload()
                });
            })
        });
    }

    showEdit() {
        this.setState({
            modal_edit_item: true
        });
    }

    showModal() {
        this.setState({
            modal_detail_item: true
        });
    }

    hideModal() {
        this.setState({
            modal_detail_item: false,
            modal_edit_item: false
        });
    }
}

export default withRouter(connect(({ auth: { isSignedIn }, cart: { query } }) => ({ isSignedIn, query }))(Shop));