import React, { Component } from "react";
import Navigation from "./Navigation";
import NavigationMobile from "./NavigationMobile";
import "../sass/component/header.scss";
import ProductCategoryApi from "../api/product_category";
import { connect } from "react-redux";
import AuthApi from "../api/auth";
import { withRouter } from "react-router-dom";

import ModalChangePassword from "./ModalChangePassword";

class Header extends Component {

  constructor(props) {
    super(props);

    this.state = {
      show_navm: false,
      show_profile: false,
      show_password: false,
      prdcat_map: []
    };

    this.toggleNavm = this.toggleNavm.bind(this);
    this.onClickSearch = this.onClickSearch.bind(this);
  }

  changePassword(data) {

    AuthApi.changePassword(data, this.props.dispatch).then(() => this.setState({ show_password: false }))
      .then((data) => {
        this.props.dispatch({ type: "SHOW_INFO", payload: "Password sucessfully changed" });
      });
  }

  componentWillMount() {
    ProductCategoryApi.getProductCategories(this.props.dispatch).then((prdcat) => {
      let prdcat_filtered = prdcat.filter(({ prdcat_nav_show }) => prdcat_nav_show === 1);
      let prdcat_map = prdcat_filtered.reduce((obj, row, i) => {
        obj[row.prdcat_parent_id] = obj[row.prdcat_parent_id] || [];
        obj[row.prdcat_parent_id].push(row);
        return obj;
      }, {});

      this.setState({ prdcat_map });

    });
  }

  toggleNavm() {
    this.setState({
      show_navm: !this.state.show_navm
    });
  }

  renderProfile() {
    // return this.props.isSignedIn && this.props.admName && <div className="adm-name">{this.props.admName.toUpperCase()}</div>
    return this.props.isSignedIn && this.props.admName && <div className="adm-name nav-wrapper"><nav><ul><li onMouseLeave={() => this.setState({ show_profile: false })}>
      <div className="nav-name" onMouseEnter={() => this.setState({ show_profile: true })}>{this.props.admName.toUpperCase()}</div>
      <div className="nav-relative">
        <ul style={{ width: "20rem", marginLeft: "-1rem" }} className={`nav-absolute ${this.state.show_profile ? "nav-absolute-show" : ""}`}>
          <li onClick={() => this.setState({ show_password: true })}>Change Password</li>
          <li onClick={() => this.onLogout()}>Logout</li>
        </ul>
      </div>
    </li></ul></nav></div>;
  }

  onClickSearch() {
    if (this.state.query) {
      this.onSearch();
    } else {
      this.refSearch.focus();
    }
  }

  onSearch() {
    //Direct ke shop/category
    if (this.state.query.trim().length < 3) {
      this.props.dispatch({
        type: "SHOW_INFO",
        payload: "Please enter at least 3 characters"
      });
    } else {
      this.props.history.push("/shop/0");
      this.props.dispatch({
        type: "UPDATE_QUERY",
        payload: this.state.query.trim()
      });
    }

  }

  renderSearchAndCart() {
    let style = {};
    if (this.state.query) {
      style.backgroundColor = "var(--grey1)";
    }
    return <div className="search-cart">
      <div className="search-container">
        <input className="search" value={this.state.query} onChange={(e) => this.setState({ query: e.target.value })} onKeyDown={(e) => e.key === "Enter" && this.onSearch()} style={style} ref={(ref) => this.refSearch = ref} /><img alt="Search Icon" src={`${process.env.PUBLIC_URL}/images/search.png`} className="icon-search" onClick={this.onClickSearch} />
      </div>
      {!this.props.isSignedIn && [<i key="0" className="icon-cart" onClick={() => this.props.history.push("/cart")} />, <span key="1" className="nav-cart-qty">{this.props.cart_qty || ""}</span>]}
    </div>;
  }

  onLogout() {
    this.props.dispatch({
      type: "SHOW_CONFIRM",
      payload: "Are you sure you want to logout ?",
      callback: () => {
        this.props.dispatch({ type: "SIGNED_OUT" });
      }
    });
  }

  confirmChangePassword(data) {
    this.props.dispatch({
      type: "SHOW_CONFIRM",
      payload: "Are you sure you want to change password ?",
      callback: () => this.changePassword(data)
    });
  }

  render() {
    let { prdcat_map, show_navm } = this.state;
    return [<header key="1">
      <div onClick={this.toggleNavm} className="navm-button"><i className="icon-menu" /></div>
      {this.renderProfile()}
      {this.renderSearchAndCart()}
      <img className="header-logo" onClick={() => this.props.history.push("/")} src={process.env.REACT_APP_API_URL + "/assets/img/logo.png"} alt="Bali Asli Logo" />
    </header>,
    <Navigation key="2" data={prdcat_map} />,
    <NavigationMobile key="3" data={prdcat_map} show={show_navm} onClick={() => this.setState({ show_navm: 0 })} />,
    <ModalChangePassword key="4" show={this.state.show_password} onClickBtnOK={(data) => this.confirmChangePassword(data)} onClickBtnCancel={() => this.setState({ show_password: false })} />];
  }
}

export default withRouter(connect(({ auth, cart: { cart } }) => {
  let cart_qty = 0;
  for (let prdcat_id in cart) {
    cart_qty += cart[prdcat_id].qty;
  }
  return {
    cart_qty,
    ...auth
  };
})(Header));
