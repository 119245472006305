import React, { Component } from "react";
import "../sass/component/wysiwyg.scss";
import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from "draft-js";

class WysiwygEditor extends Component {

    render() {
        return <Editor
            editorState={this.props.editorState}
            onEditorStateChange={this.props.onEditorStateChange}
            toolbar={{
                options: ["inline", "fontSize", "fontFamily", "list", "link"],
                inline: {
                    options: ["bold", "italic", "underline"],
                }
            }} />;
    }
}

WysiwygEditor.defaultProps = {
    editorState: EditorState.createEmpty(),
    onEditorStateChange: () => { }
};

export default WysiwygEditor;