import React, { Component } from "react";
import "../sass/component/modal-detail-item.scss";
import Modal from "../element/Modal";
import Button from "../element/ButtonSlideUp";

class ModalAvailable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: "",
            data: []
        };

        this.onClickBtnOK = this.onClickBtnOK.bind(this);
        this.onClickBtnCancel = this.onClickBtnCancel.bind(this);

        this.files = [];
        this.edited = [];
        this.refInput = [];
    }

    componentWillReceiveProps(props) {
        if (!this.props.show && props.show) {
            this.setState({ title: props.title });
            this.setState({ data: props.data });
            this.files = [];
            this.edited = [];

            for (let ri of this.refInput) {
                ri.value = "";
            }
            this.refInput = [];
        }
    }

    onClickBtnOK() {
        this.props.onClickBtnOK(this.state, this.files);
    }

    onClickBtnCancel() {
        this.refInput.map((row) => row.value = "");
        this.props.onClickBtnCancel();
    }

    render() {
        return <Modal id="modal-edit-slider"
            show={this.props.show}
            btn_ok="Save"
            btn_cancel="Cancel"
            title={"Edit Slider"}
            onClickBtnOK={this.onClickBtnOK}
            onClickBtnCancel={this.onClickBtnCancel}>
            <table className="table table-bordered">
                <tbody>
                    {this.renderLogo()}
                </tbody>
            </table>
            <br />
            <Button className="button-sm" style={{ float: "right" }} onClick={() => {
                let data = this.state.data;
                data.push({
                    img: "/assets/img/browse.png",
                    subtitle: "",
                    button_text: "",
                    title: "",
                    url: "",
                    desc: "",
                    order: data.length + 1
                });
                this.setState({ data });
            }}>+ Add new</Button>
        </Modal>;
    }

    onChangeData(i, name, value) {
        let data = JSON.parse(JSON.stringify(this.state.data));
        data[i][name] = value;
        this.setState({ data });
    }

    onChangeLogo(i, e) {
        this.files[i] = e.target.files[0];

        let data = JSON.parse(JSON.stringify(this.state.data));
        data[i].img = URL.createObjectURL(this.files[i]);
        this.edited[i] = true;

        this.setState({ data });
    }

    onRemoveItem(i) {
        let data = this.state.data;
        data.splice(i, 1);
        data.forEach((row, i) => {
            row.order = i + 1;
        });

        this.setState({ data });
    }

    renderLogo() {
        return this.state.data.map(({ img, subtitle, button_text, order, title, url, desc }, i) => {
            return [<tr key={`${i}-1`}>
                <td rowSpan="3">
                    <label htmlFor={`edit-silder-${i}`}>
                        <img src={this.edited[i] ? img : `${process.env.REACT_APP_API_URL}${img}`} alt={title} />
                    </label>
                    <input type="file" ref={(ref) => this.refInput[i] = ref} onChange={(e) => this.onChangeLogo(i, e)} id={`edit-silder-${i}`} />
                </td>
                <td colSpan="2"><input placeholder="Subtitle" type="text" onChange={({ target: { value } }) => this.onChangeData(i, "subtitle", value)} value={subtitle} /></td>
                <td rowSpan="4" ><div style={{ cursor: "pointer" }} onClick={() => this.onRemoveItem(i)}>x</div></td>
            </tr>, <tr key={`${i}-2`}>
                <td colSpan="2"><input placeholder="Title" type="text" onChange={({ target: { value } }) => this.onChangeData(i, "title", value)} value={title} /></td>
            </tr>, <tr key={`${i}-3`}>
                <td colSpan="2"><textarea placeholder="Description" type="text" onChange={({ target: { value } }) => this.onChangeData(i, "desc", value)} value={desc} /></td>
            </tr>,
            <tr key={`${i}-4`}>
                <td><input placeholder="Button text" type="text" onChange={({ target: { value } }) => this.onChangeData(i, "button_text", value)} value={button_text} /></td>
                <td><input placeholder="Link" type="text" onChange={({ target: { value } }) => this.onChangeData(i, "url", value)} value={url} /></td>
                <td className="td-order"><input placeholder="Order" type="text" onChange={({ target: { value } }) => this.onChangeData(i, "order", value)} value={order} /></td>
            </tr>,
            <tr key={`${i}-5`}>
                <td colSpan="4"><div style={{ height: "5rem" }} /></td>
            </tr>];
        });
    }
}

ModalAvailable.defaultProps = {
    data: {},
    onClickBtnOK: () => { },
    onClickBtnCancel: () => { }
};

export default ModalAvailable;