import React, { Component } from "react";
import "../sass/component/news-item.scss";
import moment from "moment";
import ButtonEdit from "../element/ButtonEdit";
import ReactHtmlParser from "react-html-parser";

class NewsItem extends Component {

    render() {
        return <div className="ni-container">
            <div className="news-btn-container">
                <div className="news-btn-container-inner">
                    <ButtonEdit onClick={this.props.onClickAdd} className="button-sm">Add</ButtonEdit>
                    <ButtonEdit onClick={() => this.props.onClickEdit(this.props.data)} className="button-sm">Edit</ButtonEdit>
                    <ButtonEdit onClick={this.props.onClickDelete} className="button-sm">Delete</ButtonEdit>
                </div>
            <img alt="bali asli" src={`${process.env.REACT_APP_API_URL}${this.props.data.news_img}`} />
            </div>
            <div className="ni-text-container">
                <div className="ni-date">{moment(this.props.data.news_date).format("DD MMMM YYYY")}</div>
                <div className="ba-title ni-title">{this.props.data.news_title}</div>
                <div className="ni-content">{ ReactHtmlParser (this.props.data.news_content) }</div>
            </div>
            
        </div>;
    }
}

NewsItem.defaultProps = {
    data: {}
};

export default NewsItem;