import React, { Component } from "react";
import "../sass/component/slider.scss";
import "../component/SliderItem";
import SliderItem from "../component/SliderItem";
import Carousel from "react-bootstrap/Carousel";
import "../sass/component/slider-item-flex.scss";
import ButtonEdit from "../element/ButtonEdit";
import ModalEditSlider from "../component/ModalEditSlider";
import { connect } from "react-redux";
import { SLIDER } from "../config/content";
import ContentApi from "../api/content";

class Slider extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected_index: 0
        };

        this.handleSelect = this.handleSelect.bind(this);
        this.onEdit = this.onEdit.bind(this);
    }

    renderSliderItem() {
        return this.props.data.map((row, i) => {
            return <Carousel.Item key={i}>
                <SliderItem data={row} />
            </Carousel.Item>;
        });
    }

    handleSelect(selected_index) {
        this.setState({ selected_index });
    };

    render() {
        return <div className="slider-container">
            <Carousel fade activeIndex={this.state.selected_index} direction={null} onSelect={this.handleSelect}>
                {this.renderSliderItem()}
            </Carousel>
            <ButtonEdit className="button-sm btn-banner" onClick={() => this.setState({ modal_edit: true })} />
            <ModalEditSlider data={this.props.data} show={this.state.modal_edit} onClickBtnOK={this.onEdit} onClickBtnCancel={() => this.setState({ modal_edit: false })} />
        </div>;
    }

    onEdit(data, files) {
        this.props.dispatch({
            type: "SHOW_CONFIRM",
            payload: "Are you sure you want to save changes ?",
            callback: () => ContentApi.updateWithFiles({ cten_name: SLIDER, cten_value: data, files }, this.props.dispatch)
                .then((data) => {
                    this.setState({ modal_edit: false });
                    this.props.dispatch({ type: "SHOW_INFO", payload: "Data sucessfully updated" });
                    this.props.dispatch({ type: "UPDATE_CONTENTS", payload: { [SLIDER]: data } });
                })
        });
    }
}

Slider.defaultProps = {
    data: []
};

export default connect(({ content: { [SLIDER]: { data } } }) => ({ data }))(Slider);