import ReactGA from "react-ga";

ReactGA.initialize("UA-164583823-1");

export default function reducer(
  state = {
    showInfo: false,
    msgInfo: "",
    cbInfo: false,
  },
  { type, payload, callback }
) {
  switch (type) {
    case "PAGE_VIEW": {
      if (state.current_page !== payload) {
        ReactGA.pageview(payload);
      }
      return {
        ...state,
        current_page: payload,
      };
    }
    case "EMAIL_VISIT": {
      ReactGA.event({
        category: "Factory Visit Inquiry",
        action: "Sent an email",
      });
      return state;
    }
    case "EMAIL_WHOLESALE": {
      ReactGA.event({
        category: "Wholesale Inquiry",
        action: "Sent an email",
      });
      return state;
    }
    case "PAYMENT_FINISH": {
      ReactGA.event({
        category: "Order Created",
        action: "Customer paid and placed an order",
      });
      return state;
    }
    default:
      return state;
  }
}
