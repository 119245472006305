import React, { Component } from "react";
import Modal from "../element/Modal";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import ORDER_STATUS from "../config/order_status";

class ModalDetailSales extends Component {

    constructor(props) {
        super(props);

        this.state = {
            sale_status: 0,
            sale_ship_no: ""
        };

        this.onUpdateSale = this.onUpdateSale.bind(this);

    }

    componentWillReceiveProps(props) {
        if (props.show !== this.props.show) {
            this.setState({
                sale_status: props.data.sale_status,
                sale_ship_no: props.data.sale_ship_no || ""
            });
        }
    }

    onUpdateSale() {
        let isStatusChanged = this.props.data.sale_status !== this.state.sale_status;
        let isShipNoChanged = this.props.data.sale_ship_no !== this.state.sale_ship_no;
        if (isShipNoChanged || isStatusChanged) {
            this.props.dispatch({
                type: "SHOW_CONFIRM",
                payload: "Do you want to save changes ?",
                callback: () => this.props.onClickBtnOK(this.state.sale_status, this.state.sale_ship_no)
            });
        }
    }

    renderOrder() {
        let { payment_type, sale_id, cnt_name, cnt_address, cnt_city, cnt_province, cnt_postal_code, created_date, sale_ship_service, sale_ship_fee, cnt_email, cnt_phone } = this.props.data;
        return <table className="table-detail-sales">
            <tr>
                <td>Order ID</td>
                <td> : </td>
                <td>{sale_id}</td>
            </tr>
            <tr>
                <td>Date</td>
                <td> : </td>
                <td>{moment.unix(created_date).format("DD MMM YYYY")}</td>
            </tr>
            <tr><td colSpan="3" />&nbsp;</tr>
            <tr>
                <td>Name</td>
                <td> : </td>
                <td>{cnt_name}</td>
            </tr>
            <tr>
                <td>Phone</td>
                <td> : </td>
                <td>{cnt_phone}</td>
            </tr>
            <tr>
                <td>Email</td>
                <td> : </td>
                <td><a href={`mailto:${cnt_email}`}>{cnt_email}</a></td>
            </tr>
            <tr>
                <td>Payment</td>
                <td> : </td>
                <td style={{ textTransform: "uppercase" }}>{payment_type ? payment_type : "unpaid"}</td>
            </tr>
            <tr>
                <td>Status</td>
                <td> : </td>
                <td>
                    <select className="detail-sales-resi" value={this.state.sale_status} onChange={({ target: { value: sale_status } }) => this.setState({ sale_status })}>
                        {Object.keys(ORDER_STATUS).map((sale_status) => <option value={sale_status} key={sale_status}>{ORDER_STATUS[sale_status]}</option>)}
                    </select>
                </td>
            </tr>
            <tr><td colSpan="3" />&nbsp;</tr>
            <tr>
                <td>Address</td>
                <td> : </td>
                <td>{cnt_address}, {cnt_city}, {cnt_province}, {cnt_postal_code}</td>
            </tr>
            <tr>
                <td>Shipment</td>
                <td> : </td>
                <td>{sale_ship_service} - IDR {sale_ship_fee.toLocaleString()}</td>
            </tr>
            <tr>
                <td>Shipment No</td>
                <td> : </td>
                <td><input className="detail-sales-resi" value={this.state.sale_ship_no} onChange={({ target: { value: sale_ship_no } }) => this.setState({ sale_ship_no })} /></td>
            </tr>
        </table>;
    }

    renderOrderDetail() {
        return <table className="table modal-detail-sales">
            <thead>
                <tr>
                    <th>No</th>
                    <th style={{ textAlign: "left" }}>Product Name</th>
                    <th style={{ textAlign: "right" }}>Quantity</th>
                    <th style={{ textAlign: "right" }}>Price</th>
                </tr>
            </thead>
            <tbody>
                {this.renderOrderDetailTR()}
                <tr>
                    <td colSpan="3" style={{ textAlign: "right", borderTop: "1px solid lightgray" }}>Total Weight (with packaging)</td>
                    <td style={{ borderTop: "1px solid lightgray", textAlign: "right" }}>{this.props.data.sales_detail.reduce((weight, { prd_weight }) => weight += prd_weight, 0)}</td>
                </tr>
                <tr>
                    <td colSpan="3" style={{ textAlign: "right", borderTop: "1px solid lightgray" }}>Shipment Fee</td>
                    <td style={{ borderTop: "1px solid lightgray", textAlign: "right" }}>{this.props.data.sale_ship_fee.toLocaleString()}</td>
                </tr>
                <tr>
                    <td colSpan="3" style={{ textAlign: "right", borderTop: "1px solid lightgray" }}>Total</td>
                    <td style={{ borderTop: "1px solid lightgray", textAlign: "right" }}>{this.props.data.sale_total.toLocaleString()}</td>
                </tr>
            </tbody>
        </table>;
    }

    renderOrderDetailTR() {
        return this.props.data.sales_detail.map(({ prd_name, prd_size, prd_variant, sdet_qty, sdet_total }, i) => <tr key={i}>
            <td>{i + 1}</td>
            <td style={{ textAlign: "left" }}>{prd_name} {prd_variant} {prd_size}</td>
            <td style={{ textAlign: "right" }}>{sdet_qty}</td>
            <td style={{ textAlign: "right" }}>{sdet_total.toLocaleString()}</td>
        </tr>);
    }

    render() {
        return <Modal id="modal-detail-sales"
            show={this.props.show}
            btn_ok="Save"
            btn_cancel="Cancel"
            title={`ORDER ID : ${this.props.data.sale_id}`}
            onClickBtnOK={this.onUpdateSale}
            onClickBtnCancel={this.props.onClickBtnCancel}>
            {this.renderOrder()}
            <br />
            {this.renderOrderDetail()}
        </Modal>;
    }
}

ModalDetailSales.defaultProps = {
    data: {
        sales_detail: [],
        sale_ship_fee: 0,
        sale_total: 0
    }
};

export default withRouter(connect(() => ({}))(ModalDetailSales));