import request from "superagent";
import Helper from "./helper";

export default {
    get: (filter, pg_current, dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        request
            .get(`${process.env.REACT_APP_API_URL}/admin/sales`)
            .set("Accept", "application/json")
            .set("Authorization", "Bearer " + localStorage.getItem("jwt"))
            .query({filter, pg_current})
            .end((err, res) => {
                Helper.onResponse(dispatch);
                if (res && res.body.status=== "success") {
                    resolve(res.body.payload);
                } else {
                    Helper.onError(err, res, dispatch);
                }
            });
    }),
    submitSales: (data, dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        request
            .post(`${process.env.REACT_APP_API_URL}/sales`)
            .set("Accept", "application/json")
            .send(data)
            .end((err, res) => {
                Helper.onResponse(dispatch);
                if (res && res.body.status=== "success") {
                    resolve(res.body.payload);
                } else {
                    Helper.onError(err, res, dispatch);
                }
            });
    }),
    update: (data, dispatch) => new Promise((resolve, reject) => {
        Helper.onRequest(dispatch);
        request
            .put(`${process.env.REACT_APP_API_URL}/admin/sales`)
            .set("Accept", "application/json")
            .set("Authorization", "Bearer " + localStorage.getItem("jwt"))
            .send(data)
            .end((err, res) => {
                Helper.onResponse(dispatch);
                if (res && res.body.status=== "success") {
                    resolve(res.body.payload);
                } else {
                    Helper.onError(err, res, dispatch);
                }
            });
    })
};