import React, { Component } from "react";

class ErrorMsg extends Component {
  render() {
    return <div className="error-msg">
      {this.props.children}
    </div>;
  }
}

export default ErrorMsg;