import React, { Component } from "react";
import "../sass/component/modal-detail-item.scss";
import Modal from "../element/Modal";
import { connect } from "react-redux";

class ModalChangePassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            current: "",
            new: "",
            confirm: ""
        };

    }

    componentWillReceiveProps(props) {
        if (!this.props.show && props.show) {
            this.setState({
                current: "",
                new: "",
                confirm: ""
            });
        }
    }

    render() {
        return <Modal id="modal-change-password"
            show={this.props.show}
            btn_ok="Save"
            btn_cancel="Cancel"
            title={"Change Password"}
            onClickBtnOK={() => this.validatePassword()}
            onClickBtnCancel={this.props.onClickBtnCancel}>
            Current Password
            <input value={this.state.current} onChange={(e) => this.setState({ current: e.target.value })} type="password" /><br />
            New Password
            <input value={this.state.new} onChange={(e) => this.setState({ new: e.target.value })} type="password" /><br />
            Confirm Password
            <input value={this.state.confirm} onChange={(e) => this.setState({ confirm: e.target.value })} type="password" /><br />
        </Modal>;
    }

    validatePassword() {
        let error_msg = "";
        if (!this.state.new || !this.state.current || !this.state.confirm) {
            error_msg = "Old, new, and confirm password cannot be empty";
        } else if (this.state.new.length < 8) {
            error_msg = "Minimum new password length is 8 characters";
        } else if (this.state.new !== this.state.confirm) {
            error_msg = "New password doesn't match with password confirmation";
        }

        if (error_msg) {
            this.props.dispatch({
                type: "SHOW_INFO",
                payload: error_msg
            });
        } else {
            this.props.onClickBtnOK(this.state);
        }
    }
}

ModalChangePassword.defaultProps = {
    data: {},
    onClickBtnOK: () => { },
    onClickBtnCancel: () => { }
};

export default connect(() => ({}))(ModalChangePassword);