export default function reducer(
  state = {
    showLoader: false,
    counter: 0,
  },
  { type, payload }
) {
  switch (type) {
    case "SHOW_LOADER": {
      return { ...state, showLoader: true, counter: state.counter + 1 };
    }
    case "HIDE_LOADER": {
      return {
        ...state,
        counter: state.counter - 1,
        showLoader: state.counter - 1 > 0,
      };
    }
    default:
      return state;
  }
}
