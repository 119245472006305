import React, { Component } from "react";
import "../sass/component/modal-detail-item.scss";

class QtyBox extends Component {

    onChange(addition) {
        let new_qty = Number(this.props.qty) + addition;
        if (new_qty > 0) {
            this.props.onChange(new_qty);
        }
    }

    render() {
        return <div className="mdi-form">
            <div onClick={() => this.onChange(-1)}>
                <i className="icon-minus" />
            </div>
            <div >
                <input onChange={(e) => (!isNaN(e.target.value) || !e.target.value) && this.props.onChange( Number(e.target.value) || 0)} value={this.props.qty} />
            </div>
            <div onClick={() => this.onChange(1)}>
                <i className="icon-plus" />
            </div>
        </div>;
    }
}

QtyBox.defaultProps = {
    qty: 1,
    onChange: () => { }
};

export default QtyBox;
