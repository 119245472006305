import React, { Component } from "react";
import "../sass/component/whole-sale.scss";
import ButtonSlideUp from "../element/ButtonSlideUp";
import ButtonEdit from "../element/ButtonEdit";
import ModalEditWholeSale from "./ModalEditWholeSale";
import { WHOLESALE_ORDER } from "../config/content";
import ContentApi from "../api/content";
import MailApi from "../api/mail";
import { connect } from "react-redux";

class WholeSale extends Component {

  constructor(props) {
    super(props);

    this.state = {
      modal_edit: false
    };

    this.onEdit = this.onEdit.bind(this);
    this.onCloseEdit = this.onCloseEdit.bind(this);
  }

  onEdit(title, desc, email, file_bg) {

    this.props.dispatch({
      type: "SHOW_CONFIRM",
      payload: "Are you sure you want to save changes ?",
      callback: () => ContentApi.updateWithFiles({ cten_name: WHOLESALE_ORDER, cten_value: { title, desc, email }, file_bg }, this.props.dispatch)
        .then((data) => {
          this.props.dispatch({ type: "SHOW_INFO", payload: "Data sucessfully updated" });
          this.props.dispatch({ type: "UPDATE_CONTENTS", payload: { [WHOLESALE_ORDER]: data } });
          this.setState({ modal_edit: false });
        })
    });

  }

  onCloseEdit() {
    this.setState({ modal_edit: false });
  }

  renderNPE() {
    return <div className="sv-npe">
      <div className="sv-row">
        
        <div className="sv-value"><input placeholder="Name" value={this.state.name} onChange={({ target: { value: name } }) => this.setState({ name })} /></div>
      </div>
      <div className="sv-row">
        
        <div className="sv-value"><input placeholder="Phone Number" value={this.state.phone} onChange={({ target: { value: phone } }) => this.setState({ phone })} /></div>
      </div>
      <div className="sv-row">
        
        <div className="sv-value"><input placeholder="E-mail address" value={this.state.email} onChange={({ target: { value: email } }) => this.setState({ email })} /></div>
      </div>
    </div>;
  }

  renderMsg() {
    return <div className="sv-msg">
      <div className="sv-row" style={{ height: "100%" }}>
        
        <div className="sv-value" style={{ height: "100%", paddingBottom: "1rem" }}><textarea placeholder="Enter your message here" rows="5" style={{ height: "100%" }} value={this.state.message} onChange={({ target: { value: message } }) => this.setState({ message })} /></div>
      </div>
    </div>;
  }

  renderInput() {
    return <div className="sv-input-container">
      {this.renderNPE()}
      {this.renderMsg()}
    </div>;
  }

  renderButton(){
    return <div className="sv-input-container">
      <div className="sv-npe" />
      <div className="sv-msg">
        <div className="sv-row">
          
          <div className="sv-value">
          <ButtonSlideUp className="button-form button-sm dark" onClick={() => this.sendEmail()}>Submit</ButtonSlideUp>
          </div>
        </div>
      </div>
    </div>;
  }

  render() {
    return <div  style={{marginTop:"-8rem"}} className="ws-container">
      <ButtonEdit className="button-sm btn-banner" onClick={() => this.setState({ modal_edit: true })} />
      <div className="vit-title">
        <span>{this.props.data.title}</span>
      </div>
      <br />
      <br />
      <br />
      <div className="sv-subtitle">
        {this.props.data.desc}
        <br/>
        <br/>
        <br/>
        <div className="sv-form">
          {this.renderInput()}
          {this.renderButton()}
        </div>
      </div>
      <ModalEditWholeSale img={this.props.data.img} title={this.props.data.title} desc={this.props.data.desc} email={this.props.data.email} onClickBtnOK={this.onEdit} onClickBtnCancel={this.onCloseEdit} show={this.state.modal_edit} />
    </div>;
  }

  sendEmail() {
    let data = {
      type: "wholesale-order",
      name: this.state.name,
      phone: this.state.phone,
      email: this.state.email,
      message: this.state.message
    };
    MailApi.send(data, this.props.dispatch)
      .then(() => {
        this.props.dispatch({type: "EMAIL_WHOLESALE"});
        this.props.dispatch({ type: "SHOW_INFO", payload: "Message sucessfully sent" });
        this.setState({
          name: "",
          phone: "",
          email: "",
          message: ""
        });
      });
  }

}

WholeSale.defaultProps = {
  data: {}
};

export default connect(({ content: { [WHOLESALE_ORDER]: data } }) => ({ data }))(WholeSale);