import React, { Component } from "react";
import "../sass/page/about.scss";
import Separator from "../element/Separator";
import { withRouter } from "react-router";
import Page from "./Page";
import ButtonSlideUp from "../element/ButtonSlideUp";

class PaymentError extends Component {

    render() {
        return <Page>
            <div style={{ textAlign: "center", fontSize: "2.5rem" }}>
                <Separator />
                Oops, something went wrong.
                <Separator height="2rem" />
                <span style={{ fontSize: "2rem" }}>Payment failed, please try again or contact our customer care.</span>
                <Separator height="2rem" />
                <ButtonSlideUp onClick={() => this.props.history.push("/cart")} className="button-sm">Back to cart</ButtonSlideUp>
                <Separator height="13rem"/>
                <br/>
            </div>
        </Page>;
    }
}

export default withRouter(PaymentError);