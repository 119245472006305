import React, { Component } from "react";
import "../sass/component/available-at.scss";
import { connect } from "react-redux";
import { AVAILABLE_AT } from "../config/content";
import ButtonEdit from "../element/ButtonEdit";
import ShopItem from "../component/ShopItem";
import ModalEditBestSeller from "./ModalEditBestSeller";
import ProductApi from "../api/product";
import ModalDetailItem from "./ModalDetailItem";

class BestSeller extends Component {

    constructor(props) {
        super(props);

        this.state = {
            modal_edit: false,
            products: [],
            best_seller: [],
            selected_item: {
                price_min: 0,
                price_max: 0,
                prd_variants: [{
                    prd_details: [{}]
                }]
            },
            selected_index: 0
        };

        this.showModalEdit = this.showModalEdit.bind(this);
        this.showModalDetail = this.showModalDetail.bind(this);
        this.hideModalEdit = this.hideModalEdit.bind(this);
        this.hideModalDetail = this.hideModalDetail.bind(this);

    }

    componentWillMount() {

        let getProducts = this.props.isSignedIn ? ProductApi.getProductsAdmin : ProductApi.getProducts;

        getProducts(this.props.dispatch)
            .then((products) => {
                this.setState({
                    products
                });
                return ProductApi.getBestSeller(this.props.dispatch);
            })
            .then((prdcode_groups) => this.setState({
                best_seller: this.state.products.filter(({ prdcode_group }) => {
                    return prdcode_groups.indexOf(prdcode_group) !== -1;
                })
            }));
    }

    showModalEdit() {
        this.setState({ modal_edit: true });
    }

    hideModalEdit() {
        this.setState({ modal_edit: false });
    }

    showModalDetail() {
        this.setState({ modal_detail: true });
    }

    hideModalDetail() {
        this.setState({ modal_detail: false });
    }

    renderShopItem() {

        return this.state.best_seller.map((row, i) => {
            let { prd_img, price_min, price_max, prd_name, prd_stock, status } = row;
            let prd_price = price_min === price_max ? price_min.toLocaleString() : `${price_min.toLocaleString()} - ${price_max.toLocaleString()}`;
            return <ShopItem key={i}
                onClick={() => this.setState({ selected_item: row, selected_index: i }, this.showModalDetail)}
                className="si-show"
                prd_stock={prd_stock}
                status={status}
                showEdit={false}
                img={prd_img}
                title={prd_name}
                price={prd_price} />;
        });
    }

    render() {
        return <div style={{ marginTop: "5rem", position: "relative" }}>
            <ButtonEdit className="button-sm btn-banner" onClick={this.showModalEdit} />
            <div className="vit-title">Best Seller<br /><br /><br /><br /></div>
            <div className="prd-best-seller filter-list row clearfix">
                {this.renderShopItem()}
            </div>
            <ModalEditBestSeller show={this.state.modal_edit} products={this.state.products} bestSeller={this.state.best_seller} onClickBtnCancel={this.hideModalEdit} onClickBtnOK={(data) => this.confirmUpsert(data)} />
            <ModalDetailItem data={this.state.selected_item} onClickBtnOK={this.showModalDetail} onClickBtnCancel={this.hideModalDetail} show={this.state.modal_detail} />
        </div>;

    }

    confirmUpsert(data) {
        if (data.length < 4) {
            this.props.dispatch({
                type: "SHOW_INFO",
                payload: "Please select at least 4 products"
            });
        } else {
            this.props.dispatch({
                type: "SHOW_CONFIRM",
                payload: "Are you sure you want to save changes ?",
                callback: () => this.onUpsertBestSeller(data)
            });
        }

    }

    onUpsertBestSeller(prdcode_groups) {
        ProductApi.upsertBestSeller({ prdcode_groups }, this.props.dispatch).then(() => {
            this.setState({
                modal_edit: false,
                best_seller: this.state.products.filter(({ prdcode_group }) => {
                    return prdcode_groups.indexOf(prdcode_group) !== -1;
                })
            });
        });
    }

}

BestSeller.defaultProps = {
    data: [],
    title: ""
};

export default connect(({ content: { [AVAILABLE_AT]: data }, auth }) => ({ ...data, ...auth }))(BestSeller);