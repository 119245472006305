import React, { Component } from "react";
import "../sass/component/modal-detail-item.scss";
import Modal from "../element/Modal";
import Button from "../element/ButtonSlideUp";

class ModalAvailable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            title: "",
            data: []
        };

        this.onClickBtnOK = this.onClickBtnOK.bind(this);
        this.onClickBtnCancel = this.onClickBtnCancel.bind(this);

        this.files = [];
        this.edited = [];
        this.refInput = [];
    }

    componentWillReceiveProps(props) {

        if (!this.props.show && props.show) {
            this.setState({ title: props.title, data: props.data });
            this.files = [];
            this.edited = [];
            for (let ri of this.refInput) {
                ri.value = "";
            }
            this.refInput = [];
            this.edited_bg = false;
            this.file_bg = false;
            if (this.refInputBG) {
                this.refInputBG.value = "";
            }
        }
    }

    onClickBtnOK() {
        this.props.onClickBtnOK(this.state, this.files, this.file_bg);
    }

    onClickBtnCancel() {
        this.refInput.map((row) => row.value = "");
        this.props.onClickBtnCancel();
    }

    render() {
        return <Modal id="modal-edit-available"
            show={this.props.show}
            btn_ok="Save"
            btn_cancel="Cancel"
            title={"Edit Available At"}
            onClickBtnOK={this.onClickBtnOK}
            onClickBtnCancel={this.onClickBtnCancel}>
            {/* <div style={{ textAlign: "center" }}>
                <label htmlFor={`edit-aa-bg`}>
                    <img style={{ width: "40rem" }} src={this.edited_bg ? this.state.img : `${process.env.REACT_APP_API_URL}${this.props.img}`} alt="Bali Asli"></img>
                </label>
                <input type="file" ref={(ref) => this.refInputBG = ref} onChange={(e) => this.onChangeLogo("background", e)} id={`edit-aa-bg`} />
            </div>

            <br /> */}
            Title :
            <br />
            <textarea onChange={({ target: { value: title } }) => this.setState({ title })} value={this.state.title} /><br /><br />
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th>Logo</th>
                        <th>Name</th>
                        <th>Order</th>
                        <th style={{ textAlign: "center" }}>x</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderLogo()}
                </tbody>
            </table>
            <br />
            <Button className="button-sm" style={{ float: "right" }} onClick={() => {
                let data = this.state.data;
                data.push({
                    alt: "",
                    img: "/assets/img/browse.png",
                    order: data.length + 1
                });
                this.setState({ data });
            }}>+ Add new</Button>
        </Modal>;
    }

    onChangeData(i, name, value) {
        let data = JSON.parse(JSON.stringify(this.state.data));
        data[i][name] = value;
        this.setState({ data });
    }

    onChangeLogo(i, e) {
        if (i === "background") {
            this.file_bg = e.target.files[0];
            this.edited_bg = true;

            this.setState({ img: URL.createObjectURL(this.file_bg) });
        } else {
            this.files[i] = e.target.files[0];

            let data = JSON.parse(JSON.stringify(this.state.data));
            data[i].img = URL.createObjectURL(this.files[i]);
            this.edited[i] = true;

            this.setState({ data });
        }
    }

    onRemoveItem(i) {
        let data = this.state.data;
        data.splice(i, 1);
        data.forEach((row, i) => {
            row.order = i + 1;
        });

        this.setState({ data });
    }

    renderLogo() {
        //Semua image pake jquery => onerror="onImgError(this); kasih default image"

        return this.state.data.map(({ alt, img, order }, i) => {
            return <tr key={i}>
                <td>
                    <label htmlFor={`edit-logo-${i}`}>
                        <img width="60rem" src={this.edited[i] ? img : `${process.env.REACT_APP_API_URL}${img}`} alt={alt} />
                    </label>
                    <input type="file" ref={(ref) => this.refInput[i] = ref} onChange={(e) => this.onChangeLogo(i, e)} id={`edit-logo-${i}`} />
                </td>
                <td><input type="text" onChange={({ target: { value } }) => this.onChangeData(i, "alt", value)} value={alt} /></td>
                <td><input type="number" onChange={({ target: { value } }) => this.onChangeData(i, "order", value)} value={order} /></td>
                <td style={{ textAlign: "center" }}><div style={{ cursor: "pointer" }} onClick={() => this.onRemoveItem(i)}>x</div></td>
            </tr>;
        });
    }
}

ModalAvailable.defaultProps = {
    data: {},
    onClickBtnOK: () => { },
    onClickBtnCancel: () => { }
};

export default ModalAvailable;