import React, { Component } from "react";
import Modal from "../element/Modal";
import { connect } from "react-redux";

class ModalInfo extends Component {

    render() {
        return <Modal id="modal-info"
            show={this.props.showInfo}
            btn_ok={"Dismiss"}
            onClickBtnOK={() => this.onDismiss()}>

            {this.props.msgInfo}

        </Modal>;
    }

    onDismiss() {
        this.props.cbInfo && this.props.cbInfo();
        this.onClose();
    }

    onClose() {
        this.props.dispatch({
            type: "HIDE_INFO"
        });
    }
}

export default connect(({ info: { showInfo, msgInfo, cbInfo } }) => ({ showInfo, msgInfo, cbInfo }))(ModalInfo);