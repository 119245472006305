import React, { Component } from "react";
import "../sass/component/modal-detail-item.scss";
import Modal from "../element/Modal";
import Wysiwyg from "../component/Wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import WysiwygHelper from "../helper/wysiwyg";

class ModalEditNews extends Component {

    constructor(props) {
        super(props);

        this.state = {
            news_title: props.data.news_title,
            news_content: props.data.news_content,
            news_img: props.data.news_img,
            editorState: WysiwygHelper.stringToEditorState(props.data.news_content || "")
        };

        this.onClickBtnOK = this.onClickBtnOK.bind(this);
    }

    componentWillReceiveProps(props) {
        if (!this.props.show && props.show) {
            this.refInput && (this.refInput.value="");
            let data = props.data || {};
            data.editorState = WysiwygHelper.stringToEditorState(data.news_content || "");
            if(this.refInput){
                this.refInput.value = "";
            }
            this.setState(data);
            this.edited = false;
            this.file = null;
            this.refInput = null;
        }
    }

    onChangeLogo(e) {
        this.file = e.target.files[0];
        this.edited = true;
        this.setState({ news_img : URL.createObjectURL(this.file) });
    }

    onClickBtnOK() {
        this.setState({
            news_content : WysiwygHelper.editorStateToString(this.state.editorState)
        }, () => {
            this.props.onClickBtnOK(this.state, this.file);
            this.refInput.value = "";
        });
        
    }

    render() {
        return <Modal id="modal-edit-news"
            show={this.props.show}
            btn_ok="Save"
            btn_cancel="Cancel"
            title={"Edit News"}
            onClickBtnOK={this.onClickBtnOK}
            onClickBtnCancel={this.props.onClickBtnCancel}>
            <div style={{ textAlign: "center" }}>
                <label htmlFor={"edit-news"}>
                    <img className="modal-abt-img" src={this.edited ? this.state.news_img : `${process.env.REACT_APP_API_URL}${this.state.news_img || "/assets/img/browse.png"}`} alt="Bali Asli" />
                </label>
                <input type="file" ref={(ref) => this.refInput = ref} onChange={(e) => this.onChangeLogo(e)} id={"edit-news"} />
            </div>
            <br/>
            Title:<br />
            <textarea rows="2" onChange={({ target: { value: news_title } }) => this.setState({ news_title })} value={this.state.news_title}>{this.state.news_title}</textarea>
            <br/><br/>Content:<br />
            <Wysiwyg
                editorState={this.state.editorState}
                onEditorStateChange={(editorState) => {
                    this.setState({
                        editorState,
                    });
                }}/>
        </Modal>;
    }
}

ModalEditNews.defaultProps = {
    data: {},
    onClickBtnOK: () => { },
    onClickBtnCancel: () => { }
};

export default ModalEditNews;